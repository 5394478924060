var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import "./PhoneInput.scss";
import React, { useEffect, useState } from "react";
var PhoneInput = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    var autoFocus = _a.autoFocus, className = _a.className, disabled = _a.disabled, id = _a.id, required = _a.required, value = _a.value, withMask = _a.withMask, onChange = _a.onChange, onFocus = _a.onFocus, onKeyDown = _a.onKeyDown, setValue = _a.setValue;
    var _m = __read(useState(""), 2), formatedPhoneNumber = _m[0], setFormattedPhoneNumber = _m[1];
    useEffect(function () {
        setFormattedPhoneNumber(formatNumber(value || ""));
    }, [value]);
    var formatNumber = function (value) {
        var _a, _b, _c;
        if (value) {
            return [
                value.substring(0, 3) || "",
                (((_a = value.substring(0, 3)) === null || _a === void 0 ? void 0 : _a.length) === 3 ? " " : "") + value.substring(3, 6) || "",
                (((_b = value.substring(3, 6)) === null || _b === void 0 ? void 0 : _b.length) === 3 ? "-" : "") + value.substring(6, 8) || "",
                (((_c = value.substring(6, 8)) === null || _c === void 0 ? void 0 : _c.length) === 2 ? "-" : "") + value.substring(8, 10) || "",
            ]
                .join("")
                .trim();
        }
        return "";
    };
    var onChangeHandler = function (e) {
        var value = e.target.value;
        if (value.match(/[A-Za-zА-Яа-яЁё]/i)) {
            e.currentTarget.value = value.substring(0, value.length - 1) + "";
            return;
        }
        var newValue = value.replace(/[^0-9]/g, "");
        if (newValue.startsWith("+7 (")) {
            newValue = newValue.slice(4);
        }
        else if (newValue.startsWith("+7(") || newValue.startsWith("+7 ") || newValue.startsWith("8 (")) {
            newValue = newValue.slice(3);
        }
        else if (newValue.startsWith("7 ") ||
            newValue.startsWith("8 ") ||
            newValue.startsWith("7(") ||
            newValue.startsWith("8(") ||
            newValue.startsWith("+7")) {
            newValue = newValue.slice(2);
        }
        else if (newValue.startsWith("79") || newValue.startsWith("89")) {
            newValue = newValue.slice(1);
        }
        if (newValue.length >= 11) {
            newValue = newValue.substring(0, newValue.length - 1);
        }
        var reg = /^[0-9]*?$/;
        if (!reg.test(newValue)) {
            e.currentTarget.value = value.substring(0, value.length - 1) + "";
        }
        setFormattedPhoneNumber(formatNumber(newValue));
        if (newValue !== "") {
            setValue && setValue(newValue);
            onChange && onChange(e);
        }
        else {
            setValue && setValue("");
        }
    };
    return (React.createElement("div", { className: 'phoneInput' },
        React.createElement("input", { className: className || "", id: id, type: 'phone', placeholder: '___ ___ __ __', value: formatedPhoneNumber, required: required, disabled: disabled, autoFocus: autoFocus, onChange: onChangeHandler, onFocus: onFocus, onKeyDown: function (e) {
                if (e.currentTarget.value.slice(-1) === "-") {
                    e.currentTarget.value = e.currentTarget.value.slice(0, -1);
                }
                onKeyDown && onKeyDown(e);
            } }),
        withMask && (React.createElement("p", null,
            !!((_b = (value || "").substring(0, 1)) === null || _b === void 0 ? void 0 : _b.length) && React.createElement("span", { className: 'phoneInput__mask__item span_1' }),
            !!((_c = (value || "").substring(1, 2)) === null || _c === void 0 ? void 0 : _c.length) && React.createElement("span", { className: 'phoneInput__mask__item span_2' }),
            !!((_d = (value || "").substring(2, 3)) === null || _d === void 0 ? void 0 : _d.length) && React.createElement("span", { className: 'phoneInput__mask__item span_3' }),
            !!((_e = (value || "").substring(3, 4)) === null || _e === void 0 ? void 0 : _e.length) && React.createElement("span", { className: 'phoneInput__mask__item span_4' }),
            !!((_f = (value || "").substring(4, 5)) === null || _f === void 0 ? void 0 : _f.length) && React.createElement("span", { className: 'phoneInput__mask__item span_5' }),
            !!((_g = (value || "").substring(5, 6)) === null || _g === void 0 ? void 0 : _g.length) && React.createElement("span", { className: 'phoneInput__mask__item span_6' }),
            !!((_h = (value || "").substring(6, 7)) === null || _h === void 0 ? void 0 : _h.length) && React.createElement("span", { className: 'phoneInput__mask__item span_7' }),
            !!((_j = (value || "").substring(7, 8)) === null || _j === void 0 ? void 0 : _j.length) && React.createElement("span", { className: 'phoneInput__mask__item span_8' }),
            !!((_k = (value || "").substring(8, 9)) === null || _k === void 0 ? void 0 : _k.length) && React.createElement("span", { className: 'phoneInput__mask__item span_9' }),
            !!((_l = (value || "").substring(9, 10)) === null || _l === void 0 ? void 0 : _l.length) && React.createElement("span", { className: 'phoneInput__mask__item span_10' })))));
};
export default PhoneInput;
