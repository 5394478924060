var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import "./CitySelectionInput.scss";
import React, { useEffect, useRef, useState } from "react";
import { useDebounce } from "Hooks/useDebounce";
import { fetchApi } from "Utils/fetchApi";
import CustomInput from "Components/CustomInput/CustomInput";
import Loader from "Components/Loader/Loader";
var CitySelectionInput = function (_a) {
    var className = _a.className, defaultUserCity = _a.defaultUserCity, disabled = _a.disabled, inputId = _a.inputId, isLoading = _a.isLoading, label = _a.label, message = _a.message, onBlurHandler = _a.onBlurHandler, placeholder = _a.placeholder, searchedCityName = _a.searchedCityName, setNewCity = _a.setNewCity, setSearchedCityName = _a.setSearchedCityName, setUserCityId = _a.setUserCityId, status = _a.status, suffixIcon = _a.suffixIcon, userCity = _a.userCity;
    var _b = __read(useState(false), 2), isHintLoading = _b[0], setIsHintLoading = _b[1];
    var _c = __read(useState([]), 2), hintsData = _c[0], setHintsData = _c[1];
    var _d = __read(useState(false), 2), showHintsSheet = _d[0], setShowHintsSheet = _d[1];
    var debouncedCityName = useDebounce(searchedCityName, 300);
    var isFirstRender = useRef(true);
    var isCitySelected = useRef(false);
    useEffect(function () {
        if (userCity && !searchedCityName) {
            setSearchedCityName(userCity.city_name);
        }
    }, []);
    useEffect(function () {
        if (debouncedCityName.length > 1 && !isFirstRender.current && !isCitySelected.current) {
            setIsHintLoading(true);
            fetchApi({ method: "GET", url: "/city/search?search=".concat(debouncedCityName) }).then(function (res) {
                if (res.data) {
                    setHintsData(res.data);
                    if (document.activeElement === document.getElementById(inputId || "citySelectionInput__input")) {
                        setShowHintsSheet(true);
                    }
                }
                else {
                    setHintsData([]);
                    setShowHintsSheet(false);
                }
                setIsHintLoading(false);
            });
        }
        isFirstRender.current = false;
        isCitySelected.current = false;
    }, [debouncedCityName]);
    useEffect(function () {
        if (!searchedCityName && hintsData.length > 0) {
            setShowHintsSheet(false);
            setHintsData([]);
            setIsHintLoading(false);
        }
    }, [searchedCityName, hintsData]);
    useEffect(function () {
        disabled && setShowHintsSheet(false);
    }, [disabled]);
    return (React.createElement("div", { className: "citySelectionInput" + (className ? " " + className : "") },
        React.createElement(CustomInput, { id: inputId || "citySelectionInput__input", label: label, disabled: !!defaultUserCity || disabled, placeholder: placeholder, inputValue: defaultUserCity || searchedCityName, autoComplete: 'new-password', onBlurHandler: function () {
                onBlurHandler && onBlurHandler();
                setShowHintsSheet(false);
            }, onChangeHandler: function (e) {
                setSearchedCityName(e.currentTarget.value);
                e.currentTarget.value === "" && setShowHintsSheet(false);
            }, suffix: isLoading || isHintLoading ? React.createElement(Loader, { className: 'citySelectionInput__loader' }) : suffixIcon, status: status, message: message }),
        React.createElement("div", { className: "customSelectHints" +
                (hintsData.length > 0 && showHintsSheet ? " customSelectHints_shown" : "") +
                (label ? " customSelectHints_withLabel" : "") }, hintsData.map(function (city) { return (React.createElement("p", { className: 'typography_tec_16_medium customSelectHints__item', key: city.city_id, onMouseDown: function (e) {
                if (document.activeElement === document.getElementById(inputId || "citySelectionInput__input")) {
                    e.preventDefault();
                }
            }, onMouseUp: function () {
                setSearchedCityName(city.city_name);
                setNewCity && setNewCity(city);
                setUserCityId && setUserCityId(city.city_id);
                isCitySelected.current = true;
                setTimeout(function () {
                    var _a;
                    (_a = document.getElementById(inputId || "citySelectionInput__input")) === null || _a === void 0 ? void 0 : _a.blur();
                });
            } },
            React.createElement("span", { className: 'customSelectHints__item__content' },
                React.createElement("span", { className: 'typography_tec_16_medium' }, city.city_name),
                React.createElement("span", { className: 'typography_tec_13_medium' }, city.region_name)))); }))));
};
export default CitySelectionInput;
