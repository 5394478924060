import Loader from "Components/Loader/Loader";
import "./SubmitBtn.scss";
import React from "react";
import { useAppSelector } from "Redux/hooks";
var SubmitBtn = function (_a) {
    var className = _a.className, controlLocation = _a.controlLocation, disabled = _a.disabled, isLoading = _a.isLoading, loadingText = _a.loadingText, _b = _a.normalSizeBP, normalSizeBP = _b === void 0 ? 600 : _b, onClick = _a.onClick, text = _a.text;
    var breakpointMatches = useAppSelector(function (state) { return state.app; }).breakpointMatches;
    return (React.createElement("button", { className: "submitBtn customBtn customBtn_contained" +
            (isLoading ? " submitBtn_withLoader" : "") +
            (controlLocation === "modal" ? " submitBtn_modalMargin" : "") +
            (breakpointMatches[normalSizeBP] || controlLocation === "drawer" ? "" : " customBtn_large") +
            (className ? " ".concat(className) : ""), disabled: disabled, onClick: !isLoading && onClick ? onClick : undefined },
        React.createElement("span", { className: 'submitBtn__text' },
            isLoading && loadingText ? loadingText : text,
            isLoading && React.createElement(Loader, { className: 'submitBtn__text__loader loader__wrapper_disabled' }))));
};
export default SubmitBtn;
