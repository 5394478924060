var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import "./CustomDrawer.scss";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { isBrowser } from "Utils/isBrowser";
var CustomDrawer = function (_a) {
    var children = _a.children, className = _a.className, getContainer = _a.getContainer, contentWrapperStyle = _a.contentWrapperStyle, _b = _a.destroyOnClose, destroyOnClose = _b === void 0 ? false : _b, footer = _a.footer, headerStyle = _a.headerStyle, _c = _a.mask, mask = _c === void 0 ? true : _c, onClose = _a.onClose, open = _a.open, _d = _a.placement, placement = _d === void 0 ? "right" : _d, rootClassName = _a.rootClassName, rootStyle = _a.rootStyle, title = _a.title;
    var _e = __read(useState(false), 2), hasTransitionedIn = _e[0], setHasTransitionedIn = _e[1];
    var drawerBodyRef = useRef(null);
    useEffect(function () {
        var _a, _b;
        if (open && !hasTransitionedIn) {
            setHasTransitionedIn(true);
            if (!getContainer) {
                document.body.style.width = "calc(100% - ".concat(innerWidth - document.body.clientWidth, "px)");
                document.body.style.overflowY = "hidden";
            }
            var isHaveFocus = (_a = drawerBodyRef.current) === null || _a === void 0 ? void 0 : _a.contains(document.activeElement);
            !isHaveFocus && ((_b = drawerBodyRef.current) === null || _b === void 0 ? void 0 : _b.focus());
        }
        else if (!open && hasTransitionedIn) {
            setTimeout(function () { return setHasTransitionedIn(false); }, 300);
        }
    }, [open, hasTransitionedIn]);
    useEffect(function () {
        return function () {
            var modalsCount = document.body.querySelectorAll(".customModal").length;
            var drawersCount = document.body.querySelectorAll(".customDrawer").length;
            if (!modalsCount && !drawersCount && !getContainer && hasTransitionedIn) {
                document.body.style.overflowY = "";
                document.body.style.width = "";
            }
        };
    }, [hasTransitionedIn]);
    var escapeHandler = useCallback(function (e) {
        var isCurrentDrawer = e.currentTarget.contains(document.activeElement);
        e.code === "Escape" && isCurrentDrawer && onClose && onClose();
    }, [onClose]);
    var DrawerJsx = (React.createElement("div", { className: "customDrawer customDrawer_".concat(placement) + (rootClassName ? " ".concat(rootClassName) : ""), tabIndex: -1, onKeyDown: escapeHandler, style: rootStyle },
        (hasTransitionedIn || open) && mask && (React.createElement("div", { onClick: onClose, className: "customDrawer__mask" +
                (hasTransitionedIn ? " customDrawer__mask_in" : "") +
                (open ? " customDrawer__mask_visible" : "") })),
        (hasTransitionedIn || open) && (React.createElement("div", { className: "customDrawer__content-wrapper customDrawer__content-wrapper-".concat(placement) +
                (hasTransitionedIn ? " customDrawer__content-wrapper_in" : "") +
                (open ? " customDrawer__content-wrapper_visible" : ""), style: contentWrapperStyle },
            React.createElement("div", { className: "customDrawer__content" + (className ? " ".concat(className) : ""), "aria-modal": true, "aria-label": 'Drawer content', role: 'dialog' },
                React.createElement("div", { className: 'customDrawer__content__main' },
                    title && (React.createElement("div", { className: 'customDrawer__content__main__header', style: headerStyle },
                        React.createElement("div", { className: 'customDrawer__content__main__header__title-wrapper' },
                            React.createElement("div", { className: 'customDrawer__content__main__header__title' }, title)))),
                    React.createElement("div", { className: 'customDrawer__content__main__body', ref: drawerBodyRef, tabIndex: 0 }, children),
                    footer && React.createElement("div", { className: 'customDrawer__content__main__footer' }, footer)))))));
    if (!hasTransitionedIn && !open && destroyOnClose)
        return null;
    return isBrowser() ? createPortal(DrawerJsx, (getContainer && getContainer()) || document.body) : DrawerJsx;
};
export default CustomDrawer;
