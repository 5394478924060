var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import "./CatalogSkeleton.scss";
import React from "react";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "Redux/hooks";
import { DesktopCatalogFiltersSkeleton } from "../DesktopCatalogFiltersSkeleton/DesktopCatalogFiltersSkeleton";
import { CatalogProductCardSkeleton } from "../CatalogProductCardSkeleton/CatalogProductCardSkeleton";
export var CatalogSkeleton = function () {
    var location = useLocation();
    var breakpointMatches = useAppSelector(function (state) { return state.app; }).breakpointMatches;
    var products = __spreadArray([], __read(Array(35)), false).map(function (_, i) { return i + 1; });
    return (React.createElement("div", { className: 'catalogSkeleton' },
        React.createElement("div", { className: 'catalogSkeleton__breadcrumbs' },
            React.createElement("div", { className: 'catalogSkeleton__breadcrumbs__item skeletonLoading' }),
            React.createElement("span", { className: 'catalogSkeleton__breadcrumbs__devider' }, "\u00A0\u00A0/\u00A0\u00A0"),
            React.createElement("div", { className: 'catalogSkeleton__breadcrumbs__item skeletonLoading' })),
        React.createElement("div", { className: 'catalogSkeleton__title skeletonLoading' }),
        React.createElement("div", { className: 'catalogSkeleton__mobileFiltersBtn skeletonLoading' }),
        React.createElement("div", { className: 'catalogSkeleton__controlsPanel' },
            React.createElement("div", { className: 'catalogSkeleton__controlsPanel__item skeletonLoading' }),
            React.createElement("div", { className: 'catalogSkeleton__controlsPanel__item skeletonLoading' }),
            React.createElement("div", { className: 'catalogSkeleton__controlsPanel__item skeletonLoading' })),
        React.createElement("div", { className: 'catalogSkeleton__mainContent' },
            React.createElement("div", { className: 'catalogSkeleton__mainContent__filters' },
                React.createElement(DesktopCatalogFiltersSkeleton, null)),
            React.createElement("div", { className: 'catalogSkeleton__mainContent__products' },
                location.pathname !== "/categories/vygoda/" &&
                    location.pathname !== "/specials/garantiya-luchshey-ceny/" && (React.createElement("div", { className: 'catalogSkeleton__mainContent__products__saleBanner skeletonLoading', style: { aspectRatio: breakpointMatches[600] ? "25 / 9" : "64 / 7" } })),
                React.createElement("div", { className: 'catalogSkeleton__mainContent__products__grid' }, products.map(function (item) { return (React.createElement(CatalogProductCardSkeleton, { key: item })); }))))));
};
