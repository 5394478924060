export var getAltDescription = function (product) {
    var result = "\u041C\u043E\u0434\u0435\u043B\u044C: ".concat(product.model, "; \u0411\u0440\u0435\u043D\u0434: ").concat(typeof product.brand === "string" ? product.brand : product.brand.name, "; ");
    product.altData &&
        Object.keys(product.altData).forEach(function (name) {
            if (name === "materials") {
                var value = product.altData[name].length ? product.altData[name].join(", ") : "Не указано";
                result += "\u041C\u0430\u0442\u0435\u0440\u0438\u0430\u043B: ".concat(value, "; ");
            }
            else if (name === "color") {
                result += "\u0426\u0432\u0435\u0442: ".concat(product.altData[name] || "Не указано", "; ");
            }
            else if (name === "country") {
                result += "\u0421\u0442\u0440\u0430\u043D\u0430 \u043F\u0440\u043E\u0438\u0437\u0432\u043E\u0434\u0441\u0442\u0432\u0430: ".concat(product.altData[name] || "Не указано");
            }
        });
    return result;
};
