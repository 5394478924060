import "./Snackbar.scss";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { changeSnackbarData } from "Redux/slices/appSlice";
import ExclamationMarkIcon from "Svg/ExclamationMarkIcon";
import SuccessMarkIcon from "Svg/SuccessMarkIcon";
var Snackbar = function (_a) {
    var className = _a.className;
    var dispatch = useAppDispatch();
    var snackbarData = useAppSelector(function (state) { return state.app; }).snackbarData;
    useEffect(function () {
        var timer;
        if (snackbarData) {
            timer = setTimeout(function () {
                dispatch(changeSnackbarData(null));
            }, 4000);
        }
        return function () {
            timer && clearTimeout(timer);
        };
    }, [snackbarData]);
    return (React.createElement("div", { className: "snackbarNotification" +
            (snackbarData ? " snackbarNotification_visible" : "") +
            (className ? " ".concat(className) : "") },
        React.createElement("div", { className: 'snackbarNotification__icon' }, (snackbarData === null || snackbarData === void 0 ? void 0 : snackbarData.status) === "success" ? React.createElement(SuccessMarkIcon, null) : React.createElement(ExclamationMarkIcon, null)),
        React.createElement("span", { className: 'snackbarNotification__msg typography_tec_button' }, snackbarData === null || snackbarData === void 0 ? void 0 : snackbarData.message)));
};
export default Snackbar;
