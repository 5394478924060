import React from "react";
var CubeIcon = function (_a) {
    var className = _a.className, _b = _a.size, size = _b === void 0 ? "medium" : _b;
    return size === "large" ? (React.createElement("svg", { width: '48', height: '48', viewBox: '0 0 48 48', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' },
        React.createElement("path", { className: className || "", fillRule: 'evenodd', clipRule: 'evenodd', d: 'M24 4L42 14V34L24 44L6 34V14L24 4Z', stroke: '#535557', strokeWidth: '2', strokeLinejoin: 'round' }),
        React.createElement("path", { d: 'M6 14L24 24L42 14', stroke: '#535557', strokeWidth: '2' }),
        React.createElement("path", { d: 'M24 24V44', stroke: '#535557', strokeWidth: '2' }))) : (React.createElement("svg", { className: className || "", width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' },
        React.createElement("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M12 2L21 7V17L12 22L3 17V7L12 2Z', stroke: '#4B4B4B', strokeWidth: '2', strokeLinejoin: 'round' }),
        React.createElement("path", { d: 'M3 7L12 12L21 7', stroke: '#4B4B4B', strokeWidth: '2' }),
        React.createElement("path", { d: 'M12 12V22', stroke: '#4B4B4B', strokeWidth: '2' })));
};
export default CubeIcon;
