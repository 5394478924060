var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import "./AuthModal.scss";
import React, { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { reformatPhone } from "Utils/reformatPhone";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { saveAuthToken, saveUserData } from "Redux/slices/userInfoSlice";
import { addNotification, changeAuthModalVisibility, changeCity, changeSnackbarData } from "Redux/slices/appSlice";
import { dataLayer } from "Static/variables";
import localStorage from "Utils/localStorage";
import { addProductToCartOrFavourites } from "Redux/slices/cartSlice";
import { fetchApi } from "Utils/fetchApi";
import { getCookie } from "Utils/getCookie";
import CustomModal from "Components/CustomModal/CustomModal";
import ArrowIcon from "Svg/ArrowIcon";
import CloseIcon from "Components/CloseIcon/CloseIcon";
import CustomInput from "Components/CustomInput/CustomInput";
import { PhoneCodesSheet } from "Components/PhoneCodesSheet/PhoneCodesSheet";
import SubmitBtn from "Components/SubmitBtn/SubmitBtn";
import FileTextIcon from "Svg/FileTextIcon";
import VerificationCode from "Components/VerificationCode/VerificationCode";
import CustomCheckbox from "Components/CustomCheckbox/CustomCheckbox";
var AuthModal = function (_a) {
    var onClose = _a.onClose;
    var dispatch = useAppDispatch();
    var navigate = useNavigate();
    var _b = useAppSelector(function (state) { return state.app; }), city = _b.city, isAuthModalShown = _b.isAuthModalShown;
    var _c = __read(useState(false), 2), isLoading = _c[0], setIsLoading = _c[1];
    var authAbortController = useRef(null);
    var _d = __read(useState(""), 2), phoneNumber = _d[0], setPhoneNumber = _d[1];
    var _e = __read(useState("7"), 2), selectedPhoneCode = _e[0], setSelectedPhoneCode = _e[1];
    var concatPhone = useRef("");
    var _f = __read(useState(true), 2), showLoginScreen = _f[0], setShowLoginScreen = _f[1];
    var _g = __read(useState(false), 2), showPolicyScreen = _g[0], setShowPolicyScreen = _g[1];
    var _h = __read(useState(false), 2), showCodeScreen = _h[0], setShowCodeScreen = _h[1];
    var _j = __read(useState(false), 2), showSuccessRegister = _j[0], setShowSuccessRegister = _j[1];
    var authType = useRef("");
    var _k = __read(useState("Войти или создать аккаунт"), 2), authTitle = _k[0], setAuthTitle = _k[1];
    var _l = __read(useState(""), 2), authSubTitle = _l[0], setAuthSubTitle = _l[1];
    useEffect(function () {
        var titleText = "Войти или создать аккаунт";
        var subTitleText = "";
        if (showPolicyScreen) {
            titleText = "Для регистрации необходимо согласие";
            subTitleText = "на обработку персональных данных";
        }
        else if (showCodeScreen) {
            titleText = "Введите код";
            subTitleText = "\u0427\u0442\u043E\u0431\u044B ".concat(authType.current === "register" ? "зарегистрироваться" : "авторизоваться", ", \u043F\u043E\u0434\u0442\u0432\u0435\u0440\u0434\u0438\u0442\u0435 \u043D\u043E\u043C\u0435\u0440 \u0442\u0435\u043B\u0435\u0444\u043E\u043D\u0430, \u043A\u043E\u0434 \u0432\u044B\u0441\u043B\u0430\u043D \u043D\u0430 \u043D\u043E\u043C\u0435\u0440:");
        }
        else if (showSuccessRegister) {
            titleText = "Вы успешно зарегистрированы";
            subTitleText =
                "Теперь в личном кабинете добавьте персональные данные, контактную информацию, адреса доставки, любимые бренды";
        }
        setAuthTitle(titleText);
        setAuthSubTitle(subTitleText);
    }, [showPolicyScreen, showCodeScreen, showSuccessRegister]);
    var backToLoginScreen = function () {
        setCounter(59);
        setPhoneNumber("");
        setSelectedPhoneCode("7");
        concatPhone.current = "";
        authType.current = "";
        setShowLoginScreen(true);
        setShowPolicyScreen(false);
        setShowCodeScreen(false);
        setShowSuccessRegister(false);
    };
    var closeHandler = function (params) {
        dispatch(changeAuthModalVisibility(false));
        onClose && onClose();
        authAbortController.current && authAbortController.current.abort();
        setIsLoading(false);
        setTimeout(backToLoginScreen, 500);
        sessionStorage.removeItem("fromDiscountPromo");
        var authRedirectURL = sessionStorage.getItem("authRedirectURL");
        (params === null || params === void 0 ? void 0 : params.withRedirect) && authRedirectURL && navigate(authRedirectURL);
        sessionStorage.removeItem("authRedirectURL");
    };
    var authHandler = function () {
        if (phoneNumber.length > 0) {
            setIsLoading(true);
            concatPhone.current = selectedPhoneCode + phoneNumber;
            authAbortController.current = new AbortController();
            fetchApi({
                method: "POST",
                url: "/auth/request-code",
                body: { phone_number: concatPhone.current },
                signal: authAbortController.current.signal,
            })
                .then(function (res) {
                setIsLoading(false);
                setShowLoginScreen(false);
                authType.current = res.data.type;
                res.data.type === "register" ? setShowPolicyScreen(true) : setShowCodeScreen(true);
            })
                .catch(function () { return setIsLoading(false); })
                .finally(function () { return (authAbortController.current = null); });
        }
        else {
            dispatch(changeSnackbarData({ message: "Некорректный номер", status: "error" }));
        }
    };
    var getCodeAndRegister = function () {
        if (showPolicyScreen) {
            setShowPolicyScreen(false);
            setShowCodeScreen(true);
        }
        fetchApi({ method: "POST", url: "/auth/register", body: { phone_number: concatPhone.current } });
    };
    var resendVerificationCode = function () {
        setCounter(59);
        fetchApi({
            method: "POST",
            url: "/auth/".concat(authType.current === "register" ? "register" : "request-code"),
            body: { phone_number: concatPhone.current },
        });
    };
    var verificationCodeHandler = function (code) {
        setIsLoading(true);
        fetchApi({
            method: "POST",
            url: "/auth/confirm",
            body: {
                admitad_uid: getCookie("admitad_uid") || "",
                code: code,
                fromDiscountPromo: sessionStorage.getItem("fromDiscountPromo") === "true",
                phone_number: concatPhone.current,
                refLinkId: localStorage.getItem("refLinkId") || "",
            },
        })
            .then(function (_a) {
            var data = _a.data;
            var currentUser = data.user;
            if (currentUser) {
                dispatch(saveAuthToken(data.token));
                dispatch(saveUserData(currentUser));
                currentUser.city.city_id !== city.city_id && dispatch(changeCity(currentUser.city));
                var localCart = localStorage.getItem("cart") || "[]";
                if (localCart !== JSON.stringify(currentUser.cart)) {
                    dispatch(addProductToCartOrFavourites({
                        newProducts: currentUser.cart,
                        location: "cart",
                        needSyncWithDB: JSON.parse(localCart).length > 0,
                        isSoftAdding: true,
                    }));
                }
                var localFavourites = localStorage.getItem("favourites") || "[]";
                if (localFavourites !== JSON.stringify(currentUser.wishlist)) {
                    dispatch(addProductToCartOrFavourites({
                        newProducts: currentUser.wishlist,
                        location: "favourites",
                        needSyncWithDB: JSON.parse(localFavourites).length > 0,
                        isSoftAdding: true,
                        skipFavouritesIndicator: true,
                    }));
                }
                if (currentUser.role.id === "1" && "yaCounter10626637" in window) {
                    var YMInstance = window.yaCounter10626637;
                    YMInstance.setUserID(currentUser.id);
                    YMInstance.params({ customLvl_1: { customLvl_2: { UserID: currentUser.id } } });
                }
                if (authType.current === "login") {
                    closeHandler({ withRedirect: true });
                }
                else {
                    dataLayer.push({
                        event: "registration",
                    });
                    setShowCodeScreen(false);
                    setShowSuccessRegister(true);
                    dispatch(addNotification({
                        category: "discount",
                        id: data.id,
                        content: "",
                    }));
                }
                sendUtmLabels();
                localStorage.removeItem("refLinkId");
            }
            setIsLoading(false);
        })
            .catch(function () {
            setIsLoading(false);
            document.querySelector(".authModal").focus();
        });
    };
    var _m = __read(useState(59), 2), counter = _m[0], setCounter = _m[1];
    useEffect(function () {
        var timer;
        if (+counter > 0 && showCodeScreen && isAuthModalShown) {
            timer = setTimeout(function () {
                if (+counter > 10) {
                    setCounter(function (c) { return +c - 1; });
                }
                else {
                    setCounter(function (c) { return "0" + (+c - 1); });
                }
            }, 1000);
        }
        return function () {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [counter, showCodeScreen, isAuthModalShown]);
    var finishRegistration = function () {
        var checkbox = document.getElementById("authModal__subscribeCheckbox");
        fetchApi({
            method: "POST",
            url: "/user/save-subscription",
            body: {
                subscribe: checkbox.checked,
            },
        }).then(function (_a) {
            var data = _a.data;
            return dispatch(saveUserData(data));
        });
        closeHandler({ withRedirect: true });
    };
    var sendUtmLabels = function () {
        var utmLabels = localStorage.getItem("utmLabels");
        if (utmLabels) {
            fetchApi({
                method: "POST",
                url: "/user/save-utm",
                body: { labels: JSON.parse(utmLabels) },
                hideErrorSnackbar: true,
            });
            localStorage.removeItem("utmLabels");
        }
    };
    return (React.createElement(CustomModal, { className: 'authModal', open: isAuthModalShown, maskClosable: false, onClose: closeHandler },
        React.createElement("div", { className: 'customModal__content__header' },
            !showLoginScreen && !showSuccessRegister && (React.createElement(ArrowIcon, { className: 'authModal__header__controls__arrowIcon', onClick: backToLoginScreen })),
            React.createElement(CloseIcon, { className: 'customModal__content__header__closeIcon', isDefaultClosePosition: true, onClick: closeHandler }),
            React.createElement("span", { className: 'typography_tec_title' }, authTitle),
            !showLoginScreen && (React.createElement("span", { className: 'customModal__content__header__subTitle typography_bodyTxt' }, authSubTitle))),
        React.createElement("div", { className: 'customModal__content__body' },
            showLoginScreen && (React.createElement("div", { className: 'authModal__loginScreen__form' },
                React.createElement(CustomInput, { autoFocus: true, disabled: isLoading, id: 'authModal__userPhone', inputValue: phoneNumber, label: '\u041A\u043E\u043D\u0442\u0430\u043A\u0442\u043D\u044B\u0439 \u0442\u0435\u043B\u0435\u0444\u043E\u043D', setInputValue: setPhoneNumber, type: 'phone', onKeyDownHandler: function (e) { return e.key === "Enter" && authHandler(); }, prefix: React.createElement(PhoneCodesSheet, { setSelectedPhoneCode: setSelectedPhoneCode, inputId: 'authModal__userPhone', selectedPhoneCode: selectedPhoneCode }) }),
                React.createElement(SubmitBtn, { controlLocation: 'modal', disabled: isLoading || (!isLoading && phoneNumber.length < 5), isLoading: isLoading, loadingText: '\u041E\u0442\u043F\u0440\u0430\u0432\u043A\u0430 \u043A\u043E\u0434\u0430', onClick: authHandler, text: '\u041F\u0440\u043E\u0434\u043E\u043B\u0436\u0438\u0442\u044C' }))),
            showPolicyScreen && (React.createElement("div", { className: 'authModal__policyScreen' },
                React.createElement("div", { className: 'authModal__policyScreen__content' },
                    React.createElement(FileTextIcon, null),
                    React.createElement(NavLink, { className: 'authModal__policyScreen__content__info', to: '/service/consentToProcessing', target: '_blank', rel: 'noopener noreferrer' },
                        React.createElement("span", { className: 'typography_h3' }, "\u041F\u043E\u043B\u0438\u0442\u0438\u043A\u0430 \u043A\u043E\u043D\u0444\u0438\u0434\u0435\u043D\u0446\u0438\u0430\u043B\u044C\u043D\u043E\u0441\u0442\u0438"),
                        React.createElement("span", { className: 'typography_captionTxt' }, "\u043E\u0431 \u0438\u0441\u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u043D\u0438\u0438 \u043F\u0435\u0440\u0441\u043E\u043D\u0430\u043B\u044C\u043D\u044B\u0445 \u0434\u0430\u043D\u043D\u044B\u0445 \u043A\u043B\u0438\u0435\u043D\u0442\u043E\u0432 \u2014 \u0444\u0438\u0437\u0438\u0447\u0435\u0441\u043A\u0438\u0445 \u043B\u0438\u0446"))),
                React.createElement(SubmitBtn, { onClick: getCodeAndRegister, controlLocation: 'modal', text: '\u041F\u0440\u043E\u0447\u0438\u0442\u0430\u043B, \u0441\u043E\u0433\u043B\u0430\u0448\u0430\u044E\u0441\u044C' }))),
            showCodeScreen && (React.createElement("div", { className: 'authModal__codeScreen' },
                React.createElement("span", { className: 'authModal__codeScreen__phoneNumber typography_bodyTxt' },
                    "+",
                    selectedPhoneCode,
                    " ",
                    reformatPhone(phoneNumber)),
                React.createElement(VerificationCode, { codeHandler: verificationCodeHandler, "is\u0421odeUnderVerifying": isLoading }),
                React.createElement(SubmitBtn, { controlLocation: 'modal', disabled: isLoading || (!isLoading && counter !== "00"), isLoading: isLoading, loadingText: '\u041F\u0440\u043E\u0432\u0435\u0440\u044F\u0435\u0442\u0441\u044F', onClick: resendVerificationCode, text: counter !== "00" ? "\u041F\u043E\u0432\u0442\u043E\u0440 \u043E\u0442\u043F\u0440\u0430\u0432\u043A\u0438 \u0447\u0435\u0440\u0435\u0437 00:".concat(counter) : "Повторить отправку" }))),
            showSuccessRegister && (React.createElement("div", { className: 'authModal__successRegister' },
                React.createElement(CustomCheckbox, { className: 'authModal__successRegister__agreements_sale', id: 'authModal__subscribeCheckbox', checked: true, darkMode: true, labelText: React.createElement("span", { className: 'typography_captionTxt' }, "\u041D\u0438\u043A\u0430\u043A\u043E\u0433\u043E \u0441\u043F\u0430\u043C\u0430, \u0442\u043E\u043B\u044C\u043A\u043E \u043B\u0443\u0447\u0448\u0438\u0435 \u043F\u0440\u0435\u0434\u043B\u043E\u0436\u0435\u043D\u0438\u044F \u0434\u043B\u044F \u0432\u0430\u0441 \u043E \u043F\u043E\u0442\u0440\u044F\u0441\u0430\u044E\u0449\u0438\u0445 \u0441\u043A\u0438\u0434\u043A\u0430\u0445 \u0438 \u0441\u0430\u043C\u044B\u0445 \u043A\u0440\u0443\u0442\u044B\u0445 \u043D\u043E\u0432\u0438\u043D\u043A\u0430\u0445"), size: 'small' }),
                React.createElement(SubmitBtn, { onClick: finishRegistration, controlLocation: 'modal', text: '\u041F\u0440\u043E\u0434\u043E\u043B\u0436\u0438\u0442\u044C' }))))));
};
export default AuthModal;
