var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import "./App.scss";
import React, { useEffect, Suspense, useState, useRef } from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import loadable from "@loadable/component";
import { getCookie } from "Utils/getCookie";
import { deleteCookie } from "Utils/deleteCookie";
import { getQueryVariable, getQueryVariableFromUrl } from "Utils/getQueryVariable";
import { apiUrl } from "Static/variables";
import { useAppSelector } from "Redux/hooks";
import { addNotification, changeAppNotifyVisibility, changeCity, changeFlexGapSupportStatus, changeFooterLinks, changeIpFriendlinessStatus, identifyDevice, removeNotificaionCategory, rewriteUnvisitedOrders, saveBreakpointMatches, saveDefaultFooterLinks, saveOptionalBlocks, savePromoActions, } from "Redux/slices/appSlice";
import { changeUserTypeDefined, saveAuthToken, saveUserData } from "Redux/slices/userInfoSlice";
import { addProductToCartOrFavourites, changeStatusOfFavouritesIndicator, rewriteCartOrFavourites, } from "Redux/slices/cartSlice";
import { getUniqueAndConcatedLocalData } from "Utils/getUniqueAndConcatedLocalData";
import { checkFlexGap } from "Utils/checkFlexGap";
import { searchTrackingForYM } from "Utils/searchTrackingForYM";
import localStorage from "Utils/localStorage";
import { getISO } from "Utils/getISO";
import { saveUtmLabels } from "Utils/saveUtmLabels";
import { fetchApi } from "Utils/fetchApi";
import { useDispatch } from "react-redux";
import { useLogout } from "Hooks/useLogout";
import { saveCookie } from "Utils/saveCookie";
import AppNotify from "Components/AppNotify/AppNotify";
import AuthModal from "Components/AuthModal/AuthModal";
import ClientWidget from "Components/ClientWidget/ClientWidget";
import Footer from "Components/Footer/Footer";
import Header from "Components/Header/Header";
import Loader from "Components/Loader/Loader";
import ManagerWidget from "Components/ManagerWidget/ManagerWidget";
import MobileNavigation from "Components/MobileNavigation/MobileNavigation";
import NotFound404 from "Components/NotFound404/NotFound404";
import Notifications from "Components/Notifications/Notifications";
import { PromoAction } from "Components/PromoAction/PromoAction";
import Snackbar from "Components/Snackbar/Snackbar";
import { CatalogSkeleton } from "Pages/Catalog/components/CatalogSkeleton/CatalogSkeleton";
var Award = loadable(function () { return import("Pages/Award/Award"); });
var Awards = loadable(function () { return import("Pages/Awards/Awards"); });
var BrandWall = loadable(function () { return import("Pages/BrandWall/BrandWall"); });
var Cart = loadable(function () { return import("Pages/Cart/Cart"); });
var Catalog = loadable(function () { return import("Pages/Catalog/Catalog"); }, { fallback: React.createElement(CatalogSkeleton, null) });
var Categories = loadable(function () { return import("Pages/Categories/Categories"); });
var CertificateRedirect = loadable(function () { return import("Pages/CertificateRedirect/CertificateRedirect"); });
var Certificates = loadable(function () { return import("Pages/Certificates/Certificates"); });
var CertificateSelection = loadable(function () { return import("Pages/CertificateSelection/CertificateSelection"); });
var Checkout = loadable(function () { return import("Pages/Checkout/Checkout"); });
var CheckoutCertificate = loadable(function () { return import("Pages/CheckoutCertificate/CheckoutCertificate"); });
var CurrentPost = loadable(function () { return import("Pages/CurrentPost/CurrentPost"); });
var Favourites = loadable(function () { return import("Pages/Favourites/Favourites"); });
var Home = loadable(function () { return import("Pages/Home/Home"); });
var Invitations = loadable(function () { return import("Pages/Invitations/Invitations"); });
var Look = loadable(function () { return import("Pages/Look/Look"); });
var LoyaltyProgram = loadable(function () { return import("Pages/LoyaltyProgram/LoyaltyProgram"); });
var ManagerLinks = loadable(function () { return import("Pages/ManagerLinks/ManagerLinks"); });
var MyAddresses = loadable(function () { return import("Pages/MyAddresses/MyAddresses"); });
var MyDetails = loadable(function () { return import("Pages/MyDetails/MyDetails"); });
var MyDiscount = loadable(function () { return import("Pages/MyDiscount/MyDiscount"); });
var Order = loadable(function () { return import("Pages/Order/Order"); });
var OrderRedirect = loadable(function () { return import("Pages/OrderRedirect/OrderRedirect"); });
var Orders = loadable(function () { return import("Pages/Orders/Orders"); });
var PersonalArea = loadable(function () { return import("Pages/PersonalArea/PersonalArea"); });
var Product = loadable(function () { return import("Pages/Product/Product"); });
var Promocodes = loadable(function () { return import("Pages/Promocodes/Promocodes"); });
var Purchases = loadable(function () { return import("Pages/Purchases/Purchases"); });
var ServicePages = loadable(function () { return import("Pages/ServicePages/ServicePages"); });
var SiteMap = loadable(function () { return import("Pages/SiteMap/SiteMap"); });
var Sizes = loadable(function () { return import("Pages/Sizes/Sizes"); });
var Subscriptions = loadable(function () { return import("Pages/Subscriptions/Subscriptions"); });
var GiftCertificate = loadable(function () { return import("Pages/GiftCertificate/GiftCertificate"); });
var RegistrationLanding = loadable(function () { return import("Pages/RegistrationLanding/RegistrationLanding"); });
export var App = function () {
    var dispatch = useDispatch();
    var logout = useLogout();
    var _a = useAppSelector(function (state) { return state.app; }), isHeaderShown = _a.isHeaderShown, deviceInfo = _a.deviceInfo, city = _a.city, notifications = _a.notifications, promoActions = _a.promoActions;
    var _b = useAppSelector(function (state) { return state.userInfo; }), user = _b.user, isUserTypeDefined = _b.isUserTypeDefined;
    useEffect(function () {
        var localToken = localStorage.getItem("token");
        var queryToken = getQueryVariable("access-token");
        var user_id = getCookie("user_id");
        var hashcode = getCookie("hashcode");
        if (localToken) {
            var tokenExp = JSON.parse(atob(localToken.split(".")[1])).exp * 1000 - 3.6e6;
            tokenExp > Date.now()
                ? fetchApi({ method: "GET", url: "/user" }).then(function (res) { return saveRespUserData(res.data, localToken); })
                : fetch(apiUrl + "/auth/refresh", {
                    method: "POST",
                    credentials: "include",
                    headers: {
                        Authorization: localToken,
                    },
                }).then(function (res) { return __awaiter(void 0, void 0, void 0, function () {
                    var refreshData;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4, res.json()];
                            case 1:
                                refreshData = _a.sent();
                                if (refreshData.status === 401) {
                                    logout();
                                    location.reload();
                                }
                                else {
                                    saveRespUserData(refreshData.user, refreshData.token);
                                }
                                return [2];
                        }
                    });
                }); });
        }
        else if (queryToken) {
            fetchApi({ method: "GET", url: "/user", headers: { Authorization: queryToken } }).then(function (res) {
                return saveRespUserData(res.data, queryToken);
            });
        }
        else if (user_id && hashcode) {
            fetchApi({ method: "POST", url: "/auth/by-hashcode", body: { user_id: user_id, hashcode: hashcode } }).then(function (res) {
                saveRespUserData(res.data.user, res.data.token);
                deleteCookie("user_id");
                deleteCookie("hashcode");
            });
        }
        else {
            dispatch(changeUserTypeDefined(true));
            dispatch(rewriteUnvisitedOrders(0));
        }
        sessionStorage.removeItem("fromDiscountPromo");
    }, []);
    useEffect(function () {
        if (isUserTypeDefined && user) {
            dispatch(rewriteCartOrFavourites({
                newProducts: user.cart,
                location: "cart",
                needSyncWithDB: false,
            }));
            dispatch(rewriteCartOrFavourites({
                newProducts: user.wishlist,
                location: "favourites",
                needSyncWithDB: false,
            }));
            if (user.role.id === "1") {
                YMIntevalId.current = setInterval(function () { return "yaCounter10626637" in window && setYMInstance(window.yaCounter10626637); }, 1000);
            }
            var admitad_uid = getCookie("admitad_uid");
            user.admitad_uid !== admitad_uid && fetchApi({ method: "POST", url: "/user/update", body: { admitad_uid: admitad_uid } });
        }
    }, [isUserTypeDefined]);
    var saveRespUserData = function (userData, token) {
        dispatch(saveAuthToken(token));
        dispatch(saveUserData(userData));
        (userData === null || userData === void 0 ? void 0 : userData.city) && userData.city.city_id !== city.city_id && dispatch(changeCity(userData.city));
        dispatch(changeUserTypeDefined(true));
    };
    useEffect(function () {
        window.addEventListener("storage", function (event) {
            var key = event.key;
            if (!document.hasFocus()) {
                if (key === "cart" || key === "favourites") {
                    dispatch(addProductToCartOrFavourites({
                        newProducts: getUniqueAndConcatedLocalData(JSON.parse(event.newValue || "[]")),
                        location: key,
                        needSyncWithDB: false,
                    }));
                }
                if (key === "token") {
                    window.location.reload();
                }
                if (key === "showFavouritesIndicator") {
                    dispatch(changeStatusOfFavouritesIndicator(!!event.newValue));
                }
            }
        });
        dispatch(identifyDevice());
        !JSON.parse(localStorage.getItem("city") || "{}").country &&
            !location.pathname.startsWith("/city") &&
            fetchApi({ method: "GET", url: "/city/closest" }).then(function (res) { return dispatch(changeCity(res.data)); });
        fetchApi({ method: "GET", url: "/settings" }).then(function (_a) {
            var data = _a.data;
            var brandsForFooter = data.brandsForFooter, discountIncrease = data.discountIncrease, isFriendlyIp = data.isFriendlyIp, optionalBlocks = data.optionalBlocks;
            dispatch(changeIpFriendlinessStatus(isFriendlyIp));
            var isWarningShown = !!notifications.find(function (n) { return n.category === "vpnWarning"; });
            if (isWarningShown && isFriendlyIp) {
                dispatch(removeNotificaionCategory("vpnWarning"));
            }
            else if (!isWarningShown && !isFriendlyIp) {
                dispatch(addNotification({ category: "vpnWarning", id: Date.now().toString(), content: "" }));
            }
            var isDiscountIncreaseNotificationMissing = !notifications.find(function (notification) { return notification.category === "discountIncrease"; });
            var lastTimeStampOfDiscountIncrease = localStorage.getItem("lastTimeStampOfDiscountIncrease");
            var currentTimeStampOfDiscountIncrease = new Date().toISOString().split("T")[0];
            if (discountIncrease &&
                ((isDiscountIncreaseNotificationMissing && !lastTimeStampOfDiscountIncrease) ||
                    (lastTimeStampOfDiscountIncrease &&
                        new Date(currentTimeStampOfDiscountIncrease) > new Date(lastTimeStampOfDiscountIncrease)))) {
                setTimeout(function () {
                    dispatch(removeNotificaionCategory("discountIncrease"));
                    dispatch(addNotification({
                        category: "discountIncrease",
                        id: Date.now().toString(),
                        content: discountIncrease,
                    }));
                    localStorage.removeItem("lastTimeStampOfDiscountIncrease");
                }, 10000);
            }
            else if (!discountIncrease) {
                dispatch(removeNotificaionCategory("discountIncrease"));
                localStorage.removeItem("lastTimeStampOfDiscountIncrease");
            }
            optionalBlocks.length > 0 && dispatch(saveOptionalBlocks(optionalBlocks));
            if (brandsForFooter.length > 0) {
                dispatch(changeFooterLinks(brandsForFooter));
                dispatch(saveDefaultFooterLinks(brandsForFooter));
            }
        });
        dispatch(changeFlexGapSupportStatus(checkFlexGap()));
        var utm_source = getQueryVariableFromUrl(location.pathname + location.search, "utm_source");
        if (utm_source) {
            saveCookie("utm_source", utm_source, { expires: new Date(Date.now() + 7.776e9) });
            if (utm_source === "admitad") {
                var admitad_uid = getQueryVariableFromUrl(location.pathname + location.search, "admitad_uid");
                admitad_uid && saveCookie("admitad_uid", admitad_uid, { expires: new Date(Date.now() + 7.776e9) });
            }
        }
    }, []);
    useEffect(function () {
        deviceInfo.deviceType === "mobile" && showAppNotify();
    }, [deviceInfo]);
    var showAppNotify = function () {
        var prevTimeAppNotify = localStorage.getItem("appNotify");
        var currentTimeAppNotify = Math.round(new Date().getTime() / 1000);
        if (deviceInfo.deviceOS !== "iPhone" &&
            deviceInfo.deviceOS !== "iPad" &&
            (!prevTimeAppNotify || currentTimeAppNotify - +prevTimeAppNotify > 0)) {
            localStorage.setItem("appNotify", String(currentTimeAppNotify));
            dispatch(changeAppNotifyVisibility(true));
        }
    };
    useEffect(function () {
        var mutationObserver = new MutationObserver(function (mutations) {
            mutations.forEach(function (mutation) {
                var target = mutation.target;
                if (target.classList.contains("digi-disable-scroll")) {
                    searchTrackingForYM();
                }
            });
        });
        mutationObserver.observe(document.body, {
            attributes: true,
        });
    }, []);
    useEffect(function () {
        isUserTypeDefined &&
            fetchApi({ method: "GET", url: "/promo-actions" }).then(function (_a) {
                var data = _a.data;
                return dispatch(savePromoActions(data));
            });
    }, [user === null || user === void 0 ? void 0 : user.id, isUserTypeDefined]);
    useEffect(function () {
        [1440, 1080, 960, 768, 600, 374].forEach(function (width) {
            var match = window.matchMedia("(max-width: ".concat(width, "px)"));
            function callback(eventList) {
                var _a;
                dispatch(saveBreakpointMatches((_a = {}, _a[width] = eventList.matches, _a)));
            }
            !!MediaQueryList.prototype.addEventListener
                ? MediaQueryList.prototype.addEventListener.apply(match, ["change", callback])
                : match.addListener(callback);
        });
    }, []);
    var YMIntevalId = useRef(null);
    var _c = __read(useState(null), 2), YMInstance = _c[0], setYMInstance = _c[1];
    useEffect(function () {
        if (YMInstance && YMIntevalId.current && user) {
            YMInstance.setUserID(user.id);
            YMInstance.params({ customLvl_1: { customLvl_2: { UserID: user.id } } });
            clearInterval(YMIntevalId.current);
        }
        return function () {
            YMIntevalId.current && clearInterval(YMIntevalId.current);
        };
    }, [YMInstance]);
    useEffect(function () {
        if (isUserTypeDefined && location.search.includes("utm_")) {
            var queryParams = location.search.slice(1).split("&");
            var utmLabels_1 = "";
            queryParams.forEach(function (el) {
                if (el.startsWith("utm_"))
                    utmLabels_1 += el + "&";
            });
            var currentLabels = { time: getISO(), utmLabels: utmLabels_1.slice(0, -1) };
            saveUtmLabels(!!user, currentLabels);
            user &&
                fetchApi({
                    method: "POST",
                    url: "/user/save-utm",
                    body: { labels: [currentLabels] },
                    hideErrorSnackbar: true,
                });
        }
    }, [isUserTypeDefined]);
    useEffect(function () {
        var refLinkId = getQueryVariable("ref");
        isUserTypeDefined && !!refLinkId && localStorage.setItem("refLinkId", refLinkId);
    }, [isUserTypeDefined]);
    return (React.createElement(BrowserRouter, null,
        React.createElement(AppNotify, null),
        React.createElement(AuthModal, null),
        isHeaderShown && React.createElement("div", { className: 'main__wrapper__header__attic__background' }),
        React.createElement("div", { className: 'main__wrapper', "data-is_header_shown": isHeaderShown },
            React.createElement(Header, null),
            isUserTypeDefined && (React.createElement(React.Fragment, null,
                React.createElement(ManagerWidget, null),
                React.createElement(ClientWidget, null),
                promoActions.map(function (promoAction) { return (React.createElement(PromoAction, { key: promoAction.name, promoActionData: promoAction })); }),
                React.createElement("main", { className: 'app__wrapper', "data-is_header_shown": isHeaderShown },
                    React.createElement(Notifications, null),
                    React.createElement(Suspense, { fallback: React.createElement(Loader, { className: 'app__wrapper__suspenseLoader' }) },
                        React.createElement(Routes, null,
                            React.createElement(Route, { path: '/', element: React.createElement(Home, null) }),
                            React.createElement(Route, { path: '/men/', element: React.createElement(Home, null) }),
                            React.createElement(Route, { path: '/women/', element: React.createElement(Home, null) }),
                            React.createElement(Route, { path: '/categories/', element: React.createElement(Categories, null) }),
                            React.createElement(Route, { path: '/brands/:slug/:category', element: React.createElement(Catalog, null) }),
                            React.createElement(Route, { path: '/brands/:slug/', element: React.createElement(Catalog, null) }),
                            React.createElement(Route, { path: '/catalog/searchByTag/:req', element: React.createElement(Catalog, null) }),
                            React.createElement(Route, { path: '/categories/:slug/', element: React.createElement(Catalog, null) }),
                            React.createElement(Route, { path: '/specials/:slug/', element: React.createElement(Catalog, null) }),
                            React.createElement(Route, { path: '/products/:productId/', element: React.createElement(Product, null) }),
                            React.createElement(Route, { path: '/look/:lookId/', element: React.createElement(Look, null) }),
                            React.createElement(Route, { path: '/brands/', element: React.createElement(BrandWall, null) }),
                            React.createElement(Route, { path: '/cart', element: React.createElement(Cart, null) }),
                            React.createElement(Route, { path: '/checkout', element: React.createElement(Checkout, null) }),
                            React.createElement(Route, { path: '/orderRedirect/:orderId', element: React.createElement(OrderRedirect, null) }),
                            React.createElement(Route, { path: '/subscriptions', element: React.createElement(Subscriptions, null) }),
                            React.createElement(Route, { path: '/orders/:token', element: React.createElement(Order, null) }),
                            React.createElement(Route, { path: '/service/consentToProcessing', element: React.createElement(ServicePages, null) }),
                            React.createElement(Route, { path: '/service/certificateRegulations', element: React.createElement(ServicePages, null) }),
                            React.createElement(Route, { path: '/sections/shipping', element: React.createElement(ServicePages, null) }),
                            React.createElement(Route, { path: '/sections/returns', element: React.createElement(ServicePages, null) }),
                            React.createElement(Route, { path: '/faq/', element: React.createElement(ServicePages, null) }),
                            React.createElement(Route, { path: '/feed/', element: React.createElement(ServicePages, null) }),
                            React.createElement(Route, { path: '/articles/', element: React.createElement(ServicePages, null) }),
                            React.createElement(Route, { path: '/city/:cityName/', element: React.createElement(ServicePages, null) }),
                            React.createElement(Route, { path: '/about/', element: React.createElement(ServicePages, null) }),
                            React.createElement(Route, { path: '/pickupPoints/', element: React.createElement(ServicePages, null) }),
                            React.createElement(Route, { path: '/news/:url/', element: React.createElement(CurrentPost, { postType: 'news' }) }),
                            React.createElement(Route, { path: '/articles/:url/', element: React.createElement(CurrentPost, { postType: 'articles' }) }),
                            React.createElement(Route, { path: '/managerLinks', element: React.createElement(ManagerLinks, null) }),
                            React.createElement(Route, { path: '/map', element: React.createElement(SiteMap, null) }),
                            React.createElement(Route, { path: '/404', element: React.createElement(NotFound404, null) }),
                            React.createElement(Route, { path: '/personalArea', element: React.createElement(PersonalArea, null) }),
                            React.createElement(Route, { path: '/myDetails', element: React.createElement(MyDetails, null) }),
                            React.createElement(Route, { path: '/myDiscount', element: React.createElement(MyDiscount, null) }),
                            React.createElement(Route, { path: '/purchases', element: React.createElement(Purchases, null) }),
                            React.createElement(Route, { path: '/orders', element: React.createElement(Orders, null) }),
                            React.createElement(Route, { path: '/favourites', element: React.createElement(Favourites, null) }),
                            React.createElement(Route, { path: '/awards', element: React.createElement(Awards, null) }),
                            React.createElement(Route, { path: '/awards/:awardId', element: React.createElement(Award, null) }),
                            React.createElement(Route, { path: '/sizes', element: React.createElement(Sizes, null) }),
                            React.createElement(Route, { path: '/myAddresses', element: React.createElement(MyAddresses, null) }),
                            React.createElement(Route, { path: '/promocodes', element: React.createElement(Promocodes, null) }),
                            React.createElement(Route, { path: '/invitations', element: React.createElement(Invitations, null) }),
                            React.createElement(Route, { path: '/loyaltyProgram', element: React.createElement(LoyaltyProgram, null) }),
                            React.createElement(Route, { path: '/certificateSelection', element: React.createElement(CertificateSelection, null) }),
                            React.createElement(Route, { path: '/checkoutCertificate', element: React.createElement(CheckoutCertificate, null) }),
                            React.createElement(Route, { path: '/certificateRedirect/:certificateHash', element: React.createElement(CertificateRedirect, null) }),
                            React.createElement(Route, { path: '/giftCertificate/:certificateNumber', element: React.createElement(GiftCertificate, null) }),
                            React.createElement(Route, { path: '/reg', element: React.createElement(RegistrationLanding, null) }),
                            React.createElement(Route, { path: '/certificates', element: React.createElement(Certificates, null) }),
                            React.createElement(Route, { path: '*', element: React.createElement(NotFound404, null) })))),
                React.createElement(MobileNavigation, null),
                React.createElement(Snackbar, null),
                React.createElement(Footer, null))))));
};
