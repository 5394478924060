var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import "Style/classes/carouselControls-arrow.scss";
import "./MainCarousel.scss";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Keyboard, FreeMode, Navigation } from "swiper/modules";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { changSelectionMarkingStatus } from "Redux/slices/homeSlice";
import { addProductToCartOrFavourites } from "Redux/slices/cartSlice";
import { sendGTMAnaliticsOnProductClick } from "Utils/sendGTMAnaliticsOnProductClick";
import { dataLayer } from "Static/variables";
import { changeSnackbarData } from "Redux/slices/appSlice";
import { getAltDescription } from "Utils/getAltDescription";
import CarouselNavArrow from "Components/CarouselNavArrow/CarouselNavArrow";
import CheckCircle from "Components/CheckCircle/CheckCircle";
import Label from "Components/Label/Label";
import { ProductPrices } from "Components/ProductPrices/ProductPrices";
import HeartIcon from "Svg/HeartIcon";
import ShopingBagIcon from "Svg/ShopingBagIcon";
var MainCarousel = function (_a) {
    var category = _a.category, categoryOfGoodsData = _a.categoryOfGoodsData, className = _a.className, gtmList = _a.gtmList, _b = _a.isLoading, isLoading = _b === void 0 ? false : _b;
    var dispatch = useAppDispatch();
    var isLooks = category === "looks";
    var _c = useAppSelector(function (state) { return state.app; }), deviceInfo = _c.deviceInfo, breakpointMatches = _c.breakpointMatches;
    var selectedProducts = useAppSelector(function (state) { return state.home; }).selectedProducts;
    var user = useAppSelector(function (state) { return state.userInfo; }).user;
    var _d = __read(useState([]), 2), selectedGoods = _d[0], setSelectedGoods = _d[1];
    useEffect(function () {
        var selectedIds = selectedProducts.map(function (selectedProduct) { return selectedProduct.id; });
        if (categoryOfGoodsData && categoryOfGoodsData.length > 0) {
            setSelectedGoods(categoryOfGoodsData.filter(function (product) { return selectedIds.includes(product.id); }).map(function (product) { return product.id; }));
        }
    }, [selectedProducts, categoryOfGoodsData]);
    var skeletonItems = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    var _e = __read(useState(isLooks ? 5 : 8), 2), numberOfSlides = _e[0], setNumberOfSlides = _e[1];
    var _f = __read(useState(16), 2), paddingOfSlides = _f[0], setPaddingOfSlides = _f[1];
    var _g = __read(useState(true), 2), isSlidesSticky = _g[0], setIsSlidesSticky = _g[1];
    useEffect(function () {
        breakpointMatches[1440] && setNumberOfSlides(isLooks ? 4 : 6);
        breakpointMatches[960] && setNumberOfSlides(isLooks ? 2 : 4);
        breakpointMatches[768] && setNumberOfSlides(isLooks ? 2 : 3);
        if (breakpointMatches[600]) {
            setNumberOfSlides(isLooks ? 2 : 3);
            setPaddingOfSlides(6);
            setIsSlidesSticky(false);
        }
        if (!breakpointMatches[600]) {
            setPaddingOfSlides(16);
            setIsSlidesSticky(true);
        }
        !breakpointMatches[768] && setNumberOfSlides(isLooks ? 2 : 4);
        !breakpointMatches[960] && setNumberOfSlides(isLooks ? 4 : 6);
        !breakpointMatches[1440] && setNumberOfSlides(isLooks ? 5 : 8);
        setTimeout(function () {
            saveArrowTopCoords();
        }, 100);
    }, [breakpointMatches]);
    var _h = __read(useState(0), 2), arrowTopValue = _h[0], setArrowTopValue = _h[1];
    useEffect(function () {
        if (categoryOfGoodsData && categoryOfGoodsData.length > 0 && !isLoading) {
            saveArrowTopCoords();
            window.addEventListener("resize", saveArrowTopCoords);
            setTimeout(function () {
                var prevArrow = document.querySelector(".mainCarousel__navArrow_prev_".concat(category));
                var nextArrow = document.querySelector(".mainCarousel__navArrow_next_".concat(category));
                if (prevArrow) {
                    prevArrow.style.opacity = "1";
                }
                if (nextArrow) {
                    nextArrow.style.opacity = "1";
                }
            }, 300);
        }
        return function () {
            window.removeEventListener("resize", saveArrowTopCoords);
        };
    }, [categoryOfGoodsData, isLoading]);
    var saveArrowTopCoords = function () {
        setTimeout(function () {
            var _a;
            var width = 0;
            var product = document.querySelector(".mainCarousel__track__slide__item__img__wrapper_".concat(category));
            if (product) {
                width = product.clientWidth;
            }
            else {
                width =
                    ((_a = document.querySelector(".mainCarousel__track__slide_sceleton__preview_".concat(category))) === null || _a === void 0 ? void 0 : _a.clientWidth) || 123;
            }
            setArrowTopValue((width + width / 3) / 2);
        }, 100);
    };
    var addToCartOrFavorites = function (location) {
        var selectedGoodsData = [];
        selectedGoods.forEach(function (id) {
            var selectedProduct = categoryOfGoodsData.find(function (product) { return product.id === id; });
            selectedProduct && selectedGoodsData.push(selectedProduct);
        });
        if (category === "looks") {
            var productsData_1 = [];
            selectedGoodsData.forEach(function (goodData) {
                if (goodData && (goodData === null || goodData === void 0 ? void 0 : goodData.products)) {
                    goodData.products.forEach(function (product) { return productsData_1.push(product); });
                }
                else if (goodData) {
                    productsData_1.push(goodData);
                }
            });
            reduxAddHandler(productsData_1.map(function (product) { return product.id; }), location);
        }
        else {
            reduxAddHandler(selectedGoods, location);
            if (location === "cart" && selectedGoodsData.length > 0) {
                dataLayer.push({ ecommerce: null });
                var options = {
                    currencyCode: "RUB",
                    add: {
                        products: selectedGoodsData.map(function (selectedProduct) { return ({
                            brand: selectedProduct.brand,
                            category: selectedProduct.category_name,
                            id: selectedProduct.id,
                            name: selectedProduct.model,
                            price: selectedProduct.actual_price,
                            quantity: 1,
                            variant: selectedProduct.sku,
                        }); }),
                    },
                };
                if (gtmList && !gtmList.startsWith("item")) {
                    options.add.actionField = { list: gtmList };
                }
                dataLayer.push({
                    event: "gtm-ee-event",
                    ecommerce: options,
                });
            }
        }
        selectedGoodsData.forEach(function (product) {
            product && dispatch(changSelectionMarkingStatus(product));
        });
        dispatch(changeSnackbarData({ message: "Товары добавлены", status: "success" }));
    };
    var reduxAddHandler = function (selectedProducts, location) {
        dispatch(addProductToCartOrFavourites({
            newProducts: Array.from(new Set(selectedProducts)).map(function (id) { return ({
                productId: id,
                sizes: [],
            }); }),
            location: location,
            needSyncWithDB: !!user,
            isSoftAdding: true,
        }));
    };
    var productPreviewClickHandler = function (products) {
        if ((!user || (user && user.role.id === "1")) && gtmList) {
            sendGTMAnaliticsOnProductClick({
                list: gtmList,
                productsForSend: products,
                fullProductsList: categoryOfGoodsData,
            });
        }
    };
    return !isLoading && categoryOfGoodsData ? (React.createElement("div", { className: "mainCarousel" + (className ? " ".concat(className) : "") },
        categoryOfGoodsData.length > 0 && (React.createElement(CarouselNavArrow, { className: "mainCarousel__navArrow_prev mainCarousel__navArrow_prev_".concat(category), type: 'prev', style: {
                top: arrowTopValue ? arrowTopValue + "px" : "",
            } })),
        React.createElement(Swiper, { className: 'mainCarousel__track', spaceBetween: paddingOfSlides, slidesPerView: numberOfSlides, keyboard: true, freeMode: {
                enabled: true,
                sticky: isSlidesSticky,
                momentumBounce: false,
            }, speed: deviceInfo.deviceType === "desktop" ? 300 : 100, threshold: deviceInfo.deviceType === "desktop" ? 0 : 3, navigation: {
                prevEl: ".mainCarousel__navArrow_prev_".concat(category),
                nextEl: ".mainCarousel__navArrow_next_".concat(category),
            }, modules: [Keyboard, FreeMode, Navigation] }, categoryOfGoodsData.map(function (product) { return (React.createElement(SwiperSlide, { className: 'mainCarousel__track__slide', key: product.id, onClick: function () { return productPreviewClickHandler([product]); } },
            React.createElement("div", { className: 'mainCarousel__track__slide' },
                React.createElement("div", { className: 'mainCarousel__track__slide__item' },
                    React.createElement("div", { className: "mainCarousel__track__slide__item__img__wrapper mainCarousel__track__slide__item__img__wrapper_".concat(category) },
                        React.createElement(CheckCircle, { condition: selectedGoods.includes(product.id), selectThis: function () { return dispatch(changSelectionMarkingStatus(product)); }, target: product }),
                        React.createElement(NavLink, { "aria-label": "preview of - ".concat(product.model || "Look by " + product.name), to: category === "looks" ? "/look/".concat(product.id, "/") : "/products/".concat(product.url, "/"), target: '_blank', rel: 'noopener noreferrer', title: product.name
                                ? "\u041E\u0431\u0440\u0430\u0437 ".concat(product.name, " ").concat(product.id, " ")
                                : "".concat(product.model).concat(product.model_full ? " " + product.model_full : ""), onError: function (e) {
                                e.currentTarget.style.backgroundColor = "var(--grey-100)";
                            }, onClick: function () { return dispatch(changSelectionMarkingStatus(product)); } },
                            React.createElement("img", { className: 'skeletonLoading', src: product.image || product.large_image, alt: "".concat(product.model ? getAltDescription(product) : "\u041E\u0431\u0440\u0430\u0437 \u043E\u0442 ".concat(product.name)), loading: deviceInfo.browserName !== "Safari" ? "lazy" : "eager", onLoad: function (e) { return e.currentTarget.classList.remove("skeletonLoading"); }, onError: function (e) {
                                    e.currentTarget.classList.remove("skeletonLoading");
                                    e.currentTarget.style.backgroundColor = "var(--grey-100)";
                                } })),
                        product.promoLabel && (React.createElement(Label, { className: 'mainCarousel__track__slide__item__img__label', labelData: product.promoLabel, size: 'small' }))),
                    React.createElement("div", { className: 'mainCarousel__track__slide__item__info' },
                        React.createElement("img", { className: 'skeletonLoading', src: product.brand_img, alt: "".concat(product.brand || product.name, " brand logo"), loading: 'lazy', onLoad: function (e) { return e.currentTarget.classList.remove("skeletonLoading"); }, onError: function (e) {
                                e.currentTarget.classList.remove("skeletonLoading");
                                e.currentTarget.style.backgroundColor = "var(--grey-100)";
                            } }),
                        React.createElement(ProductPrices, { className: 'mainCarousel__track__slide__item__info__prices', actual_price: product.actual_price, price: product.price, sale_price: product.sale_price, super_price: product.super_price })))))); })),
        selectedGoods.length > 0 && (React.createElement("div", { className: 'mainCarousel__actions' },
            React.createElement("button", { className: 'mainCarousel__actions__item customBtn customBtn_large customBtn_outline customBtn_withPrefix', onClick: function () { return addToCartOrFavorites("favourites"); } },
                React.createElement(HeartIcon, null),
                " ",
                React.createElement("span", null, "\u0412 \u0438\u0437\u0431\u0440\u0430\u043D\u043D\u043Ee")),
            React.createElement("button", { className: 'mainCarousel__actions__item customBtn customBtn_large customBtn_contained', onClick: function () { return addToCartOrFavorites("cart"); } },
                React.createElement(ShopingBagIcon, null),
                " ",
                React.createElement("span", null, "\u0412 \u043A\u043E\u0440\u0437\u0438\u043D\u0443")))),
        categoryOfGoodsData.length > 0 && (React.createElement(CarouselNavArrow, { className: "mainCarousel__navArrow_next mainCarousel__navArrow_next_".concat(category), type: 'next', style: {
                top: arrowTopValue ? arrowTopValue + "px" : "",
            } })))) : isLoading ? (React.createElement("div", { className: 'mainCarousel' },
        React.createElement(Swiper, { className: 'mainCarousel__track', spaceBetween: paddingOfSlides, slidesPerView: numberOfSlides, speed: deviceInfo.deviceType === "desktop" ? 300 : 100, threshold: deviceInfo.deviceType === "desktop" ? 0 : 3 }, skeletonItems.map(function (item) { return (React.createElement(SwiperSlide, { key: item },
            React.createElement("div", { className: 'mainCarousel__track__slide_sceleton' },
                React.createElement("div", { className: "mainCarousel__track__slide_sceleton__preview mainCarousel__track__slide_sceleton__preview_".concat(category, " skeletonLoading"), style: { minHeight: arrowTopValue * 2 + "px" } }),
                React.createElement("div", { className: 'mainCarousel__track__slide_sceleton__brand skeletonLoading' })))); })))) : null;
};
export default MainCarousel;
