import "./CheckCircle.scss";
import React from "react";
import CheckCircleIcon from "Svg/CheckCircleIcon";
var CheckCircle = function (_a) {
    var condition = _a.condition, selectThis = _a.selectThis, isSwipe = _a.isSwipe, target = _a.target;
    return (React.createElement("div", { className: 'checkCircle-wrpaper', onClick: function (e) {
            e.preventDefault();
            e.stopPropagation();
            !isSwipe && selectThis && selectThis(target, true);
        } },
        React.createElement("div", { className: 'checkCircle', style: { backgroundColor: condition ? "var(--white)" : "hsla(0, 0%, 0%, 0)" } }, condition ? React.createElement(CheckCircleIcon, null) : React.createElement("div", { className: 'checkCircle_unselected' }))));
};
export default CheckCircle;
