import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "Redux/hooks";
import { personalAreaRoutes } from "Static/personalAreaRoutes";
import NotificationCounter from "Components/NotificationCounter/NotificationCounter";
import UserIcon from "Svg/UserIcon";
export var UserControl = function (_a) {
    var className = _a.className, openAuthModal = _a.openAuthModal, user = _a.user;
    var navigate = useNavigate();
    var location = useLocation();
    var isUserTypeDefined = useAppSelector(function (state) { return state.userInfo; }).isUserTypeDefined;
    var personalAreaHandler = function () {
        if (isUserTypeDefined && user) {
            navigate("/personalArea");
            !personalAreaRoutes.some(function (route) { return location.pathname.startsWith(route); }) &&
                localStorage.setItem("prevUrl", location.pathname + location.search);
        }
        else if (isUserTypeDefined) {
            openAuthModal();
        }
    };
    return (React.createElement("div", { className: "header__top__icons__userAvatar" + (className ? " ".concat(className) : ""), onClick: personalAreaHandler },
        user ? React.createElement("img", { src: user.photo, alt: 'User avatar' }) : React.createElement(UserIcon, { size: 'large' }),
        React.createElement(NotificationCounter, { className: 'header__top__icons__counter', type: 'orders' })));
};
