var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import "./PhoneCodesSheet.scss";
import React, { useEffect, useState } from "react";
import CheckIcon from "Svg/CheckIcon";
import NavArrowIcon from "Svg/NavArrowIcon";
export var PhoneCodesSheet = function (_a) {
    var inputId = _a.inputId, selectedPhoneCode = _a.selectedPhoneCode, withoutSelect = _a.withoutSelect, setSelectedPhoneCode = _a.setSelectedPhoneCode;
    var _b = __read(useState(false), 2), showPhoneCodes = _b[0], setShowPhoneCodes = _b[1];
    useEffect(function () {
        document.addEventListener("click", closeHandler);
        return function () {
            document.removeEventListener("click", closeHandler);
        };
    }, []);
    var selectHandler = function (e) {
        e.stopPropagation();
        e.preventDefault();
        !withoutSelect && setShowPhoneCodes(function (prevShown) { return !prevShown; });
    };
    var closeHandler = function () {
        setShowPhoneCodes(false);
    };
    var phoneCodes = [
        { contry: "Afghanistan", code: "93" },
        { contry: "Albania", code: "355" },
        { contry: "Algeria", code: "21" },
        { contry: "American Samoa", code: "684" },
        { contry: "Andorra", code: "376" },
        { contry: "Angola", code: "244" },
        { contry: "Anguilla", code: "263" },
        { contry: "Antigua and Barbuda", code: "267" },
        { contry: "Argentina", code: "54" },
        { contry: "Armenia", code: "374" },
        { contry: "Australia", code: "61" },
        { contry: "Austria", code: "43" },
        { contry: "Azerbaijan", code: "994" },
        { contry: "Bahamas", code: "241" },
        { contry: "Bahrain", code: "973" },
        { contry: "Bangladesh", code: "880" },
        { contry: "Barbados", code: "245" },
        { contry: "Belarus", code: "375" },
        { contry: "Belgium", code: "32" },
        { contry: "Belize", code: "501" },
        { contry: "Benin", code: "229" },
        { contry: "Bermuda", code: "440" },
        { contry: "Bolivia", code: "591" },
        { contry: "Bosnia and Herzegovina", code: "387" },
        { contry: "Botswana", code: "267" },
        { contry: "Brazil", code: "55" },
        { contry: "British Virgin Islands", code: "283" },
        { contry: "Brunei Darusalaam", code: "673" },
        { contry: "Bulgaria", code: "359" },
        { contry: "Burkina Faso", code: "226" },
        { contry: "Burundi", code: "257" },
        { contry: "Byelorussian", code: "7" },
        { contry: "Cambodia", code: "855" },
        { contry: "Cameroon", code: "237" },
        { contry: "Canada", code: "1" },
        { contry: "Cape Verde", code: "238" },
        { contry: "Cayman Islands", code: "344" },
        { contry: "Central African Republic", code: "236" },
        { contry: "Chad", code: "235" },
        { contry: "Chile", code: "56" },
        { contry: "China", code: "86" },
        { contry: "Christmas Island", code: "672" },
        { contry: "Cocos Islands", code: "672" },
        { contry: "Colombia", code: "57" },
        { contry: "Commonwealth of the Northern Mariana Islands", code: "669" },
        { contry: "Comoros and Mayotte Island", code: "269" },
        { contry: "Congo", code: "242" },
        { contry: "Cook Islands", code: "682" },
        { contry: "Costa Rica", code: "506" },
        { contry: "Croatia", code: "385" },
        { contry: "Cuba", code: "53" },
        { contry: "Cyprus", code: "357" },
        { contry: "Czech Republic", code: "420" },
        { contry: "Denmark", code: "45" },
        { contry: "Diego Garcia", code: "246" },
        { contry: "Djibouti", code: "253" },
        { contry: "Dominica", code: "766" },
        { contry: "Dominican Republic", code: "808" },
        { contry: "East Timor", code: "62" },
        { contry: "Ecuador", code: "593" },
        { contry: "Egypt", code: "20" },
        { contry: "El Salvador", code: "503" },
        { contry: "Equatorial Guinea", code: "240" },
        { contry: "Estonia", code: "372" },
        { contry: "Ethiopia", code: "251" },
        { contry: "Faeroe Islands", code: "298" },
        { contry: "Falkland Islands", code: "500" },
        { contry: "Fiji", code: "679" },
        { contry: "Finland", code: "358" },
        { contry: "France", code: "33" },
        { contry: "French Antilles", code: "590" },
        { contry: "French Guiana", code: "594" },
        { contry: "French Polynesia", code: "689" },
        { contry: "Gabon", code: "241" },
        { contry: "Gambia", code: "220" },
        { contry: "Georgia", code: "995" },
        { contry: "Germany", code: "49" },
        { contry: "Ghana", code: "233" },
        { contry: "Gibraltar", code: "350" },
        { contry: "Greece", code: "30" },
        { contry: "Greenland", code: "299" },
        { contry: "Grenada", code: "472" },
        { contry: "Guam", code: "1" },
        { contry: "Guatemala", code: "502" },
        { contry: "Guinea", code: "224" },
        { contry: "GuineaBissau", code: "245" },
        { contry: "Guyana", code: "592" },
        { contry: "Haiti", code: "509" },
        { contry: "Honduras", code: "504" },
        { contry: "Hong Kong", code: "852" },
        { contry: "Hungary", code: "36" },
        { contry: "Iceland", code: "354" },
        { contry: "India", code: "91" },
        { contry: "Indonesia", code: "62" },
        { contry: "Iran", code: "98" },
        { contry: "Iraq", code: "964" },
        { contry: "Irish Republic", code: "353" },
        { contry: "Israel", code: "972" },
        { contry: "Italy", code: "39" },
        { contry: "Ivory Coast", code: "225" },
        { contry: "Jamaica", code: "875" },
        { contry: "Japan", code: "81" },
        { contry: "Jordan", code: "962" },
        { contry: "Kazakhstan", code: "7" },
        { contry: "Kenya", code: "254" },
        { contry: "Kirg(h)izia", code: "996" },
        { contry: "Kiribati Republic", code: "686" },
        { contry: "Kuwait", code: "965" },
        { contry: "Laos", code: "856" },
        { contry: "Latvia", code: "371" },
        { contry: "Lebanon", code: "961" },
        { contry: "Lesotho", code: "266" },
        { contry: "Liberia", code: "231" },
        { contry: "Libya", code: "21" },
        { contry: "Liechtenstein", code: "41" },
        { contry: "Lithuania", code: "370" },
        { contry: "Luxembourg", code: "352" },
        { contry: "Macao", code: "853" },
        { contry: "Macedonia", code: "389" },
        { contry: "Madagascar", code: "261" },
        { contry: "Malawi", code: "265" },
        { contry: "Malaysia", code: "60" },
        { contry: "Maldives", code: "960" },
        { contry: "Mali", code: "223" },
        { contry: "Malta", code: "356" },
        { contry: "Marshall Islands", code: "692" },
        { contry: "Martinique", code: "596" },
        { contry: "Mauritania", code: "222" },
        { contry: "Mauritius", code: "230" },
        { contry: "Mexico", code: "1557" },
        { contry: "Micronesia", code: "691" },
        { contry: "Monaco", code: "377" },
        { contry: "Mongolia", code: "976" },
        { contry: "Montserrat", code: "663" },
        { contry: "Morocco", code: "212" },
        { contry: "Mozambique", code: "258" },
        { contry: "Myanmar", code: "95" },
        { contry: "Namibia", code: "264" },
        { contry: "Nauru", code: "674" },
        { contry: "Nepal", code: "977" },
        { contry: "Netherlands Antilles", code: "599" },
        { contry: "Netherlands", code: "31" },
        { contry: "New Caledonia", code: "687" },
        { contry: "New Zealand", code: "64" },
        { contry: "Nicaragua", code: "505" },
        { contry: "Niger", code: "227" },
        { contry: "Nigeria", code: "234" },
        { contry: "Niue", code: "683" },
        { contry: "Norfolk Island", code: "672" },
        { contry: "North Korea", code: "850" },
        { contry: "North Yemen", code: "967" },
        { contry: "Northern Mariana Islands", code: "670" },
        { contry: "Norway", code: "47" },
        { contry: "Oman", code: "968" },
        { contry: "Pakistan", code: "92" },
        { contry: "Panama", code: "507" },
        { contry: "Papua New Guinea", code: "675" },
        { contry: "Paraguay", code: "595" },
        { contry: "Peru", code: "51" },
        { contry: "Philippines", code: "63" },
        { contry: "Poland", code: "48" },
        { contry: "Portugal", code: "351" },
        { contry: "Puerto Rico", code: "786" },
        { contry: "Qatar", code: "974" },
        { contry: "Republic of San Marino", code: "378" },
        { contry: "Reunion", code: "262" },
        { contry: "Romania", code: "40" },
        { contry: "Russia", code: "7" },
        { contry: "Rwandese Republic", code: "250" },
        { contry: "Saint Helena and Ascension Island", code: "247" },
        { contry: "Saint Pierre et Miquelon", code: "508" },
        { contry: "San Marino", code: "39" },
        { contry: "Sao Tome e Principe", code: "239" },
        { contry: "Saudi Arabia", code: "966" },
        { contry: "Senegal", code: "221" },
        { contry: "Seychelles", code: "248" },
        { contry: "Sierra Leone", code: "232" },
        { contry: "Singapore", code: "65" },
        { contry: "Slovakia", code: "421" },
        { contry: "Slovenia", code: "386" },
        { contry: "Solomon Islands", code: "677" },
        { contry: "Somalia", code: "252" },
        { contry: "South Africa", code: "27" },
        { contry: "South Korea", code: "82" },
        { contry: "South Yemen", code: "969" },
        { contry: "Spain", code: "34" },
        { contry: "Sri Lanka", code: "94" },
        { contry: "St.Kitts and Nevis", code: "868" },
        { contry: "St.Lucia", code: "757" },
        { contry: "St.Vincent and the Grenadines", code: "783" },
        { contry: "Sudan", code: "249" },
        { contry: "Suriname", code: "597" },
        { contry: "Svalbard and Jan Mayen Islands", code: "47" },
        { contry: "Swaziland", code: "268" },
        { contry: "Sweden", code: "46" },
        { contry: "Switzerland", code: "41" },
        { contry: "Syria", code: "963" },
        { contry: "Ta(d)jikistan", code: "992" },
        { contry: "Taiwan", code: "886" },
        { contry: "Tanzania", code: "255" },
        { contry: "Thailand", code: "66" },
        { contry: "Togolese Republic", code: "228" },
        { contry: "Tokelau", code: "690" },
        { contry: "Tonga", code: "676" },
        { contry: "Trinidad and Tobago", code: "867" },
        { contry: "Tunisia", code: "21" },
        { contry: "Turkey", code: "90" },
        { contry: "Turkmenistan", code: "993" },
        { contry: "Turks & Caicos Islands", code: "648" },
        { contry: "Tuvalu", code: "688" },
        { contry: "Uganda", code: "256" },
        { contry: "Ukraine", code: "380" },
        { contry: "United Arab Emirates", code: "971" },
        { contry: "United Kingdom", code: "44" },
        { contry: "Uruguay", code: "598" },
        { contry: "US Virgin Islands", code: "339" },
        { contry: "USA", code: "1" },
        { contry: "Uzbekistan", code: "998" },
        { contry: "Vanuatu", code: "678" },
        { contry: "Vatican City", code: "39" },
        { contry: "Venezuela", code: "58" },
        { contry: "Vietnam", code: "84" },
        { contry: "Wallis and Futuna Islands", code: "681" },
        { contry: "Western Sahara", code: "21" },
        { contry: "Western Samoa", code: "685" },
        { contry: "Yugoslavia", code: "381" },
        { contry: "Zaire", code: "243" },
        { contry: "Zambia", code: "260" },
        { contry: "Zimbabwe", code: "263" },
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { onClick: selectHandler, className: 'phoneCodesSheet__span-select' },
            !withoutSelect && React.createElement(NavArrowIcon, { style: { transform: showPhoneCodes ? "rotate(0deg)" : "rotate(180deg)" } }),
            React.createElement("span", { className: 'typography_tec_16_medium' },
                "+",
                selectedPhoneCode)),
        React.createElement("div", { className: "customSelectHints phoneCodes__hints" + (showPhoneCodes ? " customSelectHints_shown" : "") }, phoneCodes.map(function (obj) { return (React.createElement("p", { key: obj.contry, className: 'customSelectHints__item typography_tec_16_medium', onClick: function () {
                setSelectedPhoneCode && setSelectedPhoneCode(obj.code);
                inputId && document.getElementById(inputId).focus();
                setShowPhoneCodes(false);
            } },
            React.createElement("span", { className: 'customSelectHints__item__content phoneCodes__hints__item' },
                "+",
                obj.code,
                " ",
                obj.contry,
                selectedPhoneCode === obj.code && React.createElement(CheckIcon, { className: 'customSelectHints__item__content__checkIcon' })))); }))));
};
