import React from "react";
var ArrowShortIcon = function (_a) {
    var className = _a.className, onClick = _a.onClick, style = _a.style, _b = _a.size, size = _b === void 0 ? "large" : _b;
    return size === "large" ? (React.createElement("svg", { className: className || "", style: style, width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg', onClick: onClick },
        React.createElement("path", { d: 'M14 6L8 12L14 18', stroke: '#4B4B4B', strokeWidth: '2', strokeLinecap: 'round', strokeLinejoin: 'round' }))) : size === "medium" ? (React.createElement("svg", { className: className || "", style: style, width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg', onClick: onClick },
        React.createElement("path", { d: 'M7.5702 14.7482L12.5199 9.79842C12.9105 9.40789 12.9105 8.77473 12.5199 8.3842L12.3432 8.20743C11.9526 7.8169 11.3195 7.8169 10.929 8.20743L5.97921 13.1572C5.58868 13.5477 5.58868 14.1809 5.97921 14.5714L6.15598 14.7482C6.54651 15.1387 7.17967 15.1387 7.5702 14.7482Z', fill: '#A1A4A8' }),
        React.createElement("path", { d: 'M17.2931 13.1569L12.3433 8.20711C11.9528 7.81658 11.3196 7.81658 10.9291 8.20711L10.7523 8.38388C10.3618 8.77441 10.3618 9.40757 10.7523 9.7981L15.7021 14.7478C16.0926 15.1384 16.7258 15.1384 17.1163 14.7478L17.2931 14.5711C17.6836 14.1805 17.6836 13.5474 17.2931 13.1569Z', fill: '#A1A4A8' }))) : (React.createElement("svg", { className: className || "", style: style, width: '16', height: '16', viewBox: '0 0 16 16', fill: 'none', xmlns: 'http://www.w3.org/2000/svg', onClick: onClick },
        React.createElement("path", { d: 'M10.4696 10.409L7.28765 7.22702C6.99476 6.93412 6.51989 6.93412 6.22699 7.22702C5.9341 7.51991 5.9341 7.99478 6.22699 8.28768L9.40898 11.4697C9.70187 11.7625 10.1767 11.7625 10.4696 11.4697C10.7625 11.1768 10.7625 10.7019 10.4696 10.409Z', fill: '#A1A4A8' }),
        React.createElement("path", { d: 'M9.40897 4.04504L6.22699 7.22702C5.93409 7.51992 5.93409 7.99479 6.22699 8.28768C6.51988 8.58058 6.99475 8.58058 7.28765 8.28768L10.4696 5.1057C10.7625 4.81281 10.7625 4.33794 10.4696 4.04504C10.1767 3.75215 9.70186 3.75215 9.40897 4.04504Z', fill: '#A1A4A8' })));
};
export default ArrowShortIcon;
