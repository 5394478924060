var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import "./NotificationCounter.scss";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { rewriteNotifications, rewriteUnvisitedOrders } from "Redux/slices/appSlice";
import { isBrowser } from "Utils/isBrowser";
var NotificationCounter = function (_a) {
    var type = _a.type, className = _a.className;
    var dispatch = useAppDispatch();
    var _b = useAppSelector(function (state) { return state.app; }), notifications = _b.notifications, unvisitedOrders = _b.unvisitedOrders;
    var _c = useAppSelector(function (state) { return state.cart; }), productsInCart = _c.productsInCart, productsInFavourites = _c.productsInFavourites;
    var _d = __read(useState(0), 2), counter = _d[0], setCounter = _d[1];
    var wrappers = isBrowser() ? window.document.querySelectorAll(".notificationCounter_".concat(type)) : [];
    var copyArr = __spreadArray([], __read(wrappers), false);
    useEffect(function () {
        switch (type) {
            case "common":
                commonLogic();
                break;
            case "cart":
                cartLogic();
                break;
            case "orders":
                ordersLogic();
                break;
            case "favourites":
                favouritesLogic();
                break;
            default:
                break;
        }
    }, [counter, notifications, type, unvisitedOrders]);
    var total = notifications.reduce(function (accumulator, currentValue) { var _a; return accumulator + ((_a = currentValue === null || currentValue === void 0 ? void 0 : currentValue.value) === null || _a === void 0 ? void 0 : _a.length); }, 0);
    useEffect(function () {
        type === "common" && setCounter(total);
    }, [total]);
    useEffect(function () {
        window.addEventListener("storage", commonStorageListener);
        return function () { return window.removeEventListener("storage", commonStorageListener); };
    }, []);
    var commonLogic = function () {
        setCounter(total);
        copyArr.forEach(function (el) { return (el.style.display = total === 0 ? "none" : "block"); });
    };
    var commonStorageListener = function (event) {
        var _a;
        if (event.key === "notifications" && event.oldValue !== event.newValue && ((_a = event.storageArea) === null || _a === void 0 ? void 0 : _a.notifications)) {
            var newNotifications = JSON.parse(event.storageArea.notifications);
            dispatch(rewriteNotifications({ data: newNotifications }));
            var newCounter_1 = newNotifications.reduce(function (accumulator, currentValue) { var _a; return accumulator + ((_a = currentValue === null || currentValue === void 0 ? void 0 : currentValue.value) === null || _a === void 0 ? void 0 : _a.length); }, 0);
            setCounter(newCounter_1);
            if (newCounter_1 > 0) {
                copyArr.forEach(function (el) { return (el.innerHTML = "".concat(newCounter_1)); });
            }
            else {
                copyArr.forEach(function (el) { return (el.style.display = "none"); });
            }
        }
    };
    var cartLogic = function () {
        setCounter(productsInCart.length);
        var savedLocalProducts = localStorage.getItem("cart");
        var counterNumber = savedLocalProducts ? JSON.parse(savedLocalProducts).length : 0;
        var wrappers = isBrowser() ? window.document.querySelectorAll(".notificationCounter_".concat(type)) : [];
        var copyArr = __spreadArray([], __read(wrappers), false);
        if (counter + counterNumber === 0) {
            copyArr.forEach(function (el) { return (el.style.display = "none"); });
        }
        else if (counter > 0) {
            copyArr.forEach(function (el) { return (el.style.display = "block"); });
        }
        window.onstorage = function (event) {
            if (event.key === "cart" && event.oldValue !== event.newValue && event.storageArea.cart) {
                var newCounter = JSON.parse(event.storageArea.cart).length;
                setCounter(newCounter);
                if (!!newCounter) {
                    copyArr.forEach(function (el) { return (el.style.display = "block"); });
                }
                else {
                    copyArr.forEach(function (el) { return (el.style.display = "none"); });
                }
            }
        };
    };
    useEffect(function () {
        type === "cart" && setCounter(productsInCart.length);
    }, [productsInCart.length]);
    useEffect(function () {
        if (type === "orders") {
            window.addEventListener("storage", ordersStorageListener);
        }
        return function () { return window.removeEventListener("storage", ordersStorageListener); };
    }, []);
    var ordersLogic = function () {
        setCounter(unvisitedOrders);
        copyArr.forEach(function (el) { return (el.style.display = unvisitedOrders > 0 ? "block" : "none"); });
    };
    var ordersStorageListener = function (event) {
        var _a;
        if (event.key === "unvisitedOrders" && event.oldValue !== event.newValue && ((_a = event.storageArea) === null || _a === void 0 ? void 0 : _a.unvisitedOrders)) {
            var newUnvisitedOrders_1 = +JSON.parse(event.storageArea.unvisitedOrders);
            dispatch(rewriteUnvisitedOrders(newUnvisitedOrders_1));
            setCounter(newUnvisitedOrders_1);
            if (newUnvisitedOrders_1 > 0) {
                copyArr.forEach(function (el) { return (el.innerHTML = "".concat(newUnvisitedOrders_1)); });
            }
            else {
                copyArr.forEach(function (el) { return (el.style.display = "none"); });
            }
        }
    };
    var favouritesLogic = function () {
        setCounter(productsInFavourites.length);
        var savedLocalProducts = localStorage.getItem("favourites");
        var counterNumber = savedLocalProducts ? JSON.parse(savedLocalProducts).length : 0;
        var wrappers = isBrowser() ? window.document.querySelectorAll(".notificationCounter_".concat(type)) : [];
        var copyArr = __spreadArray([], __read(wrappers), false);
        if (!counter || !counterNumber) {
            copyArr.forEach(function (el) { return (el.style.display = "none"); });
        }
        else {
            copyArr.forEach(function (el) { return (el.style.display = "block"); });
        }
        window.onstorage = function (event) {
            if (event.key === "favourites" && event.oldValue !== event.newValue && event.storageArea.favourites) {
                var newCounter = JSON.parse(event.storageArea.favourites).length;
                setCounter(newCounter);
                if (newCounter) {
                    copyArr.forEach(function (el) { return (el.style.display = "none"); });
                }
            }
        };
    };
    useEffect(function () {
        type === "favourites" && setCounter(productsInFavourites.length);
    }, [productsInFavourites.length]);
    return (React.createElement("div", { className: "notificationCounter notificationCounter_".concat(type) + (className ? " " + className : "") }, counter));
};
export default NotificationCounter;
