import React from "react";
import { useAppSelector } from "Redux/hooks";
var SearchIcon = function (_a) {
    var className = _a.className, onClick = _a.onClick, _b = _a.size, size = _b === void 0 ? "normal" : _b;
    var isFriendlyIp = useAppSelector(function (state) { return state.app; }).isFriendlyIp;
    return size === "small" ? (React.createElement("svg", { className: className || "", "data-is_friendly_ip": isFriendlyIp, width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg', onClick: onClick },
        React.createElement("g", { id: 'ic_search_24' },
            React.createElement("path", { id: 'Path 5', d: 'M16.3434 15.3434L21 20', stroke: '#535557', strokeWidth: '2', strokeLinecap: 'round' }),
            React.createElement("path", { id: 'Oval', fillRule: 'evenodd', clipRule: 'evenodd', d: 'M11 17C14.866 17 18 13.866 18 10C18 6.13401 14.866 3 11 3C7.13401 3 4 6.13401 4 10C4 13.866 7.13401 17 11 17Z', stroke: '#535557', strokeWidth: '2' })))) : size === "normal" ? (React.createElement("svg", { className: className || "", "data-is_friendly_ip": isFriendlyIp, width: '28', height: '28', viewBox: '0 0 28 28', fill: 'none', xmlns: 'http://www.w3.org/2000/svg', onClick: onClick },
        React.createElement("g", { clipPath: 'url(#clip0_424_12825)' },
            React.createElement("path", { d: 'M19.2747 19.2747L23 23', stroke: '#525D73', strokeWidth: '2', strokeLinecap: 'round' }),
            React.createElement("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M13 21C17.4183 21 21 17.4183 21 13C21 8.58172 17.4183 5 13 5C8.58172 5 5 8.58172 5 13C5 17.4183 8.58172 21 13 21Z', stroke: '#525D73', strokeWidth: '2' })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: 'clip0_424_12825' },
                React.createElement("rect", { width: '28', height: '28', fill: 'white' }))))) : (React.createElement("svg", { className: className || "", "data-is_friendly_ip": isFriendlyIp, width: '36', height: '36', viewBox: '0 0 36 36', fill: 'none', xmlns: 'http://www.w3.org/2000/svg', onClick: onClick },
        React.createElement("path", { d: 'M24.7819 24.7818L29.5716 29.5714', stroke: '#B6BCC4', strokeWidth: '2', strokeLinecap: 'round' }),
        React.createElement("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M16.0716 27C22.1073 27 27.0002 22.1071 27.0002 16.0715C27.0002 10.0358 22.1073 5.14288 16.0716 5.14288C10.036 5.14288 5.14307 10.0358 5.14307 16.0715C5.14307 22.1071 10.036 27 16.0716 27Z', stroke: '#B6BCC4', strokeWidth: '2' })));
};
export default SearchIcon;
