import BrandsGrid from "Components/BrandsGrid/BrandsGrid";
import "./PopularBrands.scss";
import React from "react";
var PopularBrands = function (_a) {
    var className = _a.className, isLoading = _a.isLoading, popularBrandsData = _a.popularBrandsData;
    return (React.createElement("div", { className: "popularBrands" + (className ? " ".concat(className) : "") },
        React.createElement("p", { className: 'popularBrands__title typography_title' }, "\u041F\u043E\u043F\u0443\u043B\u044F\u0440\u043D\u044B\u0435 \u0431\u0440\u0435\u043D\u0434\u044B"),
        React.createElement(BrandsGrid, { className: 'popularBrands__grid', brandsData: popularBrandsData, isLoading: isLoading })));
};
export default PopularBrands;
