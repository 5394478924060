import "./CloseIcon.scss";
import React from "react";
var CloseIcon = function (_a) {
    var className = _a.className, _b = _a.isDefaultClosePosition, isDefaultClosePosition = _b === void 0 ? false : _b, onClick = _a.onClick, _c = _a.size, size = _c === void 0 ? "regular" : _c, style = _a.style, withBackground = _a.withBackground;
    return (React.createElement("div", { className: "closeIcon-wrapper" +
            (isDefaultClosePosition ? " closeIcon-wrapper_defaultClosePosition" : "") +
            (className ? " ".concat(className) : ""), style: style, "data-size": size, onClick: onClick },
        React.createElement("div", { className: "closeIcon" + " closeIcon_".concat(size) + (withBackground ? " closeIcon_withBackground" : "") },
            React.createElement("div", { className: "closeIcon__item" + " closeIcon__item_".concat(size) }),
            React.createElement("div", { className: "closeIcon__item" + " closeIcon__item_".concat(size) }))));
};
export default CloseIcon;
