import TagManager from "react-gtm-module";
import { isBrowser } from "Utils/isBrowser";
var isBrowserEnv = isBrowser();
export var isDevMode = process.env.NODE_ENV === "development";
export var apiUrl = process.env.API_URL
    ? process.env.API_URL
    : isDevMode
        ? "https://api2.feature-orders-redesign.dev.lsboutique.ru/apix/v2"
        : "https://api2.lsboutique.ru/apix/v2";
export var botsApiUrl = process.env.API_BOTS_URL ? process.env.API_BOTS_URL : apiUrl;
export var siteHost = process.env.SITE_HOST
    ? process.env.SITE_HOST
    : "https://".concat(isDevMode ? "dev." : "", "lsboutique.ru");
export var crmUrl = siteHost + "/crm";
export var adminHost = process.env.ADMIN_HOST
    ? process.env.ADMIN_HOST
    : "https://".concat(isDevMode ? "dev." : "", "lsboutique.ru");
export var dataLayer = isBrowserEnv ? window.dataLayer || [] : [];
var tagManagerArgs = {
    gtmId: "GTM-KMNDWNG",
    auth: isDevMode ? "nlzUqI3jMctFMug9zFgbcQ" : "YCFpcSclgeNj052UaLqhsw",
    preview: isDevMode ? "env-100" : "env-2",
};
if (!isDevMode && isBrowserEnv) {
    window.onload = function () {
        setTimeout(function () {
            var role = localStorage.getItem("role");
            if (!role || role === "1") {
                TagManager.initialize(tagManagerArgs);
                dataLayer = window.dataLayer;
                dataLayer.push({ originalLocation: location.href });
                var url = sessionStorage.getItem("originalLocation");
                if (url) {
                    dataLayer.push({ originalLocation_session: url });
                }
                else {
                    sessionStorage.setItem("originalLocation", location.href);
                    dataLayer.push({ originalLocation_session: location.href });
                }
            }
            else {
                dataLayer = [];
            }
        }, 5000);
    };
}
else if (isBrowserEnv && !location.host.startsWith("localhost")) {
    window.onload = function () {
        setTimeout(function () {
            TagManager.initialize(tagManagerArgs);
            dataLayer = window.dataLayer;
        }, 5000);
    };
}
else {
    dataLayer = [];
}
export var _tmr = isBrowserEnv ? window._tmr || [] : [];
export var mailRu_TrackerId = isDevMode ? "3209890" : "3263248";
export var WA_number = "79107912931";
export var YMerchantId = "d3e95488-a7ad-4854-a1fa-a76e098bfd22";
