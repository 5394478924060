import PhoneInput from "Components/PhoneInput/PhoneInput";
import "./CustomInput.scss";
import React from "react";
var CustomInput = function (_a) {
    var autoComplete = _a.autoComplete, autoFocus = _a.autoFocus, className = _a.className, defaultValue = _a.defaultValue, disabled = _a.disabled, id = _a.id, inputValue = _a.inputValue, integerOnly = _a.integerOnly, label = _a.label, max = _a.max, message = _a.message, min = _a.min, onBlurHandler = _a.onBlurHandler, onChangeHandler = _a.onChangeHandler, onFocusHandler = _a.onFocusHandler, onKeyDownHandler = _a.onKeyDownHandler, placeholder = _a.placeholder, prefix = _a.prefix, required = _a.required, setInputValue = _a.setInputValue, status = _a.status, style = _a.style, suffix = _a.suffix, _b = _a.type, type = _b === void 0 ? "text" : _b, withMask = _a.withMask;
    return (React.createElement("div", { className: "customInput customInput_wrapper" +
            (className ? " ".concat(className) : "") +
            (status ? " customInput_".concat(status) : "") +
            (disabled ? " customInput_disabled" : ""), style: style },
        label && (React.createElement("label", { className: 'typography_tec_13_medium customInput__label', htmlFor: id }, label)),
        React.createElement("div", { className: 'customInput__main', style: { padding: type === "date" ? "0 14px" : "0" } },
            prefix && React.createElement("div", { className: 'customInput__main__prefix' }, prefix),
            type === "phone" ? (React.createElement(PhoneInput, { className: "typography_tec_16_medium" + (suffix ? "" : " customInput__main__phoneInputWithPadding"), id: id, value: inputValue, required: required, withMask: withMask, disabled: disabled, autoFocus: autoFocus, setValue: setInputValue, onChange: onChangeHandler, onKeyDown: onKeyDownHandler, onFocus: onFocusHandler })) : (React.createElement("input", { className: "typography_tec_16_medium customInput__main__input" + (type === "number" ? " withoutArrows" : ""), autoComplete: autoComplete, autoFocus: autoFocus, style: {
                    paddingLeft: prefix || type === "date" ? "" : "16px",
                    paddingRight: suffix || type === "date" ? "" : "16px",
                }, defaultValue: defaultValue, disabled: disabled, id: id, max: max, min: min, placeholder: placeholder, required: required, type: type, value: inputValue, onChange: function (e) {
                    onChangeHandler && onChangeHandler(e);
                    setInputValue && setInputValue(e.target.value);
                }, onKeyDown: function (e) {
                    if (integerOnly &&
                        isNaN(+e.key) &&
                        e.key !== "0" &&
                        e.key !== "Backspace" &&
                        e.key !== "Enter" &&
                        e.key !== "Tab" &&
                        e.key !== "ArrowLeft" &&
                        e.key !== "ArrowRight" &&
                        !e.ctrlKey) {
                        e.preventDefault();
                        return;
                    }
                    onKeyDownHandler && onKeyDownHandler(e);
                }, onFocus: function (e) { return onFocusHandler && onFocusHandler(e); }, onBlur: function (e) { return onBlurHandler && onBlurHandler(e); } })),
            suffix && React.createElement("div", { className: 'customInput__main__suffix' }, suffix),
            disabled && React.createElement("div", { className: 'customInput__disableMask' })),
        message && (React.createElement("span", { className: "typography_tec_13_medium customInput__message" + (status === "error" ? " customInput__message_error" : "") }, message))));
};
export default CustomInput;
