import NotificationCounter from "Components/NotificationCounter/NotificationCounter";
import "./MobileNavigation.scss";
import React from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { changeAuthModalVisibility } from "Redux/slices/appSlice";
import { personalAreaRoutes } from "Static/personalAreaRoutes";
import CatalogIcon from "Svg/CatalogIcon";
import HomeIcon from "Svg/HomeIcon";
import ShopingBagIcon from "Svg/ShopingBagIcon";
import HeartIcon_mobile from "Svg/HeartIcon_mobile";
import UserIcon from "Svg/UserIcon";
var MobileNavigation = function (_a) {
    var className = _a.className;
    var dispatch = useAppDispatch();
    var navigate = useNavigate();
    var location = useLocation();
    var _b = useAppSelector(function (state) { return state.userInfo; }), user = _b.user, isUserTypeDefined = _b.isUserTypeDefined;
    var personalAreaHandler = function () {
        if (isUserTypeDefined && user) {
            navigate("/personalArea");
            !personalAreaRoutes.some(function (route) { return location.pathname.startsWith(route); }) &&
                localStorage.setItem("prevUrl", location.pathname + location.search);
        }
        else {
            openAuthModal();
        }
    };
    var favouritesPageHandler = function () {
        navigate("/favourites");
        !personalAreaRoutes.some(function (route) { return location.pathname.startsWith(route); }) &&
            localStorage.setItem("prevUrl", location.pathname + location.search);
    };
    var openAuthModal = function () {
        dispatch(changeAuthModalVisibility(true));
    };
    return (React.createElement("nav", { className: "mobileNavigation" + (className ? " ".concat(className) : "") },
        React.createElement(NavLink, { className: 'mobileNavigation__link', to: '/' },
            React.createElement(HomeIcon, { className: 'mobileNavigation__link__svg' }),
            React.createElement("span", { className: 'mobileNavigation__link__txt typography_captionTxt' }, "\u0413\u043B\u0430\u0432\u043D\u0430\u044F")),
        React.createElement(NavLink, { className: 'mobileNavigation__link', to: '/categories/new_season/' },
            React.createElement(CatalogIcon, { className: 'mobileNavigation__link__svg' }),
            React.createElement("span", { className: 'mobileNavigation__link__txt typography_captionTxt' }, "\u041A\u0430\u0442\u0430\u043B\u043E\u0433")),
        React.createElement(NavLink, { className: 'mobileNavigation__link', to: '/cart' },
            React.createElement("span", { className: 'mobileNavigation__link__icon' },
                React.createElement(ShopingBagIcon, { className: 'mobileNavigation__link__icon__svg', size: 'normal' }),
                React.createElement(NotificationCounter, { className: 'mobileNavigation__link__icon__counter', type: 'cart' })),
            React.createElement("span", { className: 'mobileNavigation__link__txt typography_captionTxt' }, "\u041A\u043E\u0440\u0437\u0438\u043D\u0430")),
        React.createElement("div", { className: "mobileNavigation__link" + (location.pathname === "/favourites" ? " active" : ""), onClick: favouritesPageHandler },
            React.createElement("span", { className: 'mobileNavigation__link__icon' },
                React.createElement(HeartIcon_mobile, { className: 'mobileNavigation__link__icon__svg' }),
                React.createElement(NotificationCounter, { className: 'mobileNavigation__link__icon__counter', type: 'favourites' })),
            React.createElement("span", { className: 'mobileNavigation__link__txt typography_captionTxt' }, "\u0418\u0437\u0431\u0440\u0430\u043D\u043D\u043E\u0435")),
        React.createElement("div", { className: "mobileNavigation__link" + (location.pathname === "/personalArea" ? " active" : ""), onClick: personalAreaHandler },
            React.createElement("span", { className: 'mobileNavigation__link__icon' },
                React.createElement(UserIcon, { className: 'mobileNavigation__link__icon__svg', size: 'normal' }),
                React.createElement(NotificationCounter, { className: 'mobileNavigation__link__icon__counter', type: 'orders' })),
            React.createElement("span", { className: 'mobileNavigation__link__txt typography_captionTxt' }, "\u041A\u0430\u0431\u0438\u043D\u0435\u0442"))));
};
export default MobileNavigation;
