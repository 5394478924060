import CustomPopover from "Components/CustomPopover/CustomPopover";
import { useAppSelector } from "Redux/hooks";
import React from "react";
export var IconPopover = function (_a) {
    var children = _a.children, messangerName = _a.messangerName;
    var deviceInfo = useAppSelector(function (state) { return state.app; }).deviceInfo;
    return (React.createElement(CustomPopover, { overlayClassName: 'clientWidget__popover', content: React.createElement("span", { className: 'clientWidget__popover__txt typography_captionTxt' },
            "\u041D\u0430\u043F\u0438\u0441\u0430\u0442\u044C \u0432 ",
            messangerName), displayDelayMS: 200, placement: 'left', popoverMargin: 24, trigger: deviceInfo.deviceType === "desktop" ? ["hover"] : [] },
        React.createElement("div", null, children)));
};
