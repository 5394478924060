var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import MobileAppQrModal from "Components/MobileAppQrModal/MobileAppQrModal";
import "./MobileAppQr.scss";
import React, { useState } from "react";
var MobileAppQr = function () {
    var _a = __read(useState(false), 2), isQrModalShown = _a[0], setIsQrModalShown = _a[1];
    var closeHandler = function () { return setIsQrModalShown(false); };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'mobileAppQr', onClick: function () { return setIsQrModalShown(true); } },
            React.createElement("span", { className: 'typography_tec_16_medium' }, "\u0421\u043A\u0430\u0447\u0438\u0432\u0430\u0439\u0442\u0435 \u043C\u043E\u0431\u0438\u043B\u044C\u043D\u043E\u0435 \u043F\u0440\u0438\u043B\u043E\u0436\u0435\u043D\u0438\u0435"),
            React.createElement("picture", null,
                React.createElement("source", { srcSet: 'https://cdn.lsboutique.ru/images/ls.net.webp', type: 'image/webp' }),
                React.createElement("img", { src: 'https://cdn.lsboutique.ru/images/ls.net.jpg', alt: 'app logo', loading: 'lazy' }))),
        React.createElement(MobileAppQrModal, { isShown: isQrModalShown, closeHandler: closeHandler })));
};
export default MobileAppQr;
