var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import CustomInput from "Components/CustomInput/CustomInput";
import "./VerificationCode.scss";
import React, { useEffect, useState } from "react";
var VerificationCode = function (_a) {
    var className = _a.className, codeHandler = _a.codeHandler, isСodeUnderVerifying = _a.isСodeUnderVerifying;
    var _b = __read(useState(""), 2), firstCodeNumber = _b[0], setFirstCodeNumber = _b[1];
    var _c = __read(useState(""), 2), secondCodeNumber = _c[0], setSecondCodeNumber = _c[1];
    var _d = __read(useState(""), 2), thirdCodeNumber = _d[0], setThirdCodeNumber = _d[1];
    var _e = __read(useState(""), 2), fourthCodeNumber = _e[0], setFourthCodeNumber = _e[1];
    useEffect(function () {
        var _a;
        (_a = document.getElementById("firstCodeNumber")) === null || _a === void 0 ? void 0 : _a.focus();
    }, []);
    var changeFocus = function (nextInpuId) {
        var _a;
        nextInpuId && ((_a = document.getElementById(nextInpuId)) === null || _a === void 0 ? void 0 : _a.focus());
    };
    useEffect(function () {
        var code = firstCodeNumber + secondCodeNumber + thirdCodeNumber + fourthCodeNumber;
        code.length === 4 && codeHandler(code);
    }, [firstCodeNumber, secondCodeNumber, thirdCodeNumber, fourthCodeNumber]);
    var backToPrevInput = function (e, setValue, prevInputId) {
        var _a;
        if (e.key === "Backspace") {
            e.preventDefault();
            setValue("");
            prevInputId && ((_a = document.getElementById(prevInputId)) === null || _a === void 0 ? void 0 : _a.focus());
        }
    };
    return (React.createElement("div", { className: "verificationCode" + (className ? " ".concat(className) : "") }, [
        {
            id: "firstCodeNumber",
            value: firstCodeNumber,
            setValue: setFirstCodeNumber,
            nextId: "secondCodeNumber",
            prevId: "",
        },
        {
            id: "secondCodeNumber",
            value: secondCodeNumber,
            setValue: setSecondCodeNumber,
            nextId: "thirdCodeNumber",
            prevId: "firstCodeNumber",
        },
        {
            id: "thirdCodeNumber",
            value: thirdCodeNumber,
            setValue: setThirdCodeNumber,
            nextId: "fourthCodeNumber",
            prevId: "secondCodeNumber",
        },
        {
            id: "fourthCodeNumber",
            value: fourthCodeNumber,
            setValue: setFourthCodeNumber,
            nextId: "",
            prevId: "thirdCodeNumber",
        },
    ].map(function (_a) {
        var id = _a.id, value = _a.value, setValue = _a.setValue, nextId = _a.nextId, prevId = _a.prevId;
        return (React.createElement("div", { className: 'verificationCode__item', key: id },
            React.createElement(CustomInput, { autoComplete: 'new-password', autoFocus: id === "firstCodeNumber", disabled: isСodeUnderVerifying, id: id, inputValue: value, integerOnly: true, type: 'number', onChangeHandler: function (e) {
                    var code = e.nativeEvent.data || e.currentTarget.value;
                    if (code.length === 4) {
                        setFirstCodeNumber(code[0]);
                        setSecondCodeNumber(code[1]);
                        setThirdCodeNumber(code[2]);
                        setFourthCodeNumber(code[3]);
                        changeFocus("fourthCodeNumber");
                    }
                    else {
                        changeFocus(nextId);
                        setValue(code);
                    }
                }, onKeyDownHandler: function (e) { return backToPrevInput(e, setValue, prevId); } })));
    })));
};
export default VerificationCode;
