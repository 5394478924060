import "./IncreasedDiscountTimer.scss";
import React, { useEffect } from "react";
import { useCountdown } from "Hooks/useCountdown";
import { declOfNum } from "Utils/declOfNum";
export var IncreasedDiscountTimer = function (_a) {
    var className = _a.className, discountPercent = _a.discountPercent, expirationDate = _a.expirationDate, onTimerEnd = _a.onTimerEnd;
    var _b = useCountdown(new Date(expirationDate).getTime()), days = _b.days, hours = _b.hours, minutes = _b.minutes, seconds = _b.seconds;
    var numberOfDays = +days;
    useEffect(function () {
        if (+days + +hours + +minutes + +seconds <= 0) {
            onTimerEnd && onTimerEnd();
        }
    }, [days, hours, minutes, seconds]);
    return (React.createElement("div", { className: "increasedDiscountTimer" + (className ? " ".concat(className) : "") },
        React.createElement("span", { className: 'increasedDiscountTimer__label typography_h4' }, "+".concat(discountPercent, "%")),
        React.createElement("div", { className: 'typography_captionTxt' }, numberOfDays > 0
            ? "".concat(numberOfDays, " ").concat(declOfNum(numberOfDays, ["день", "дня", "дней"]))
            : "".concat(hours, ":").concat(minutes, ":").concat(seconds))));
};
