import React from "react";
var StoreLogoIcon = function (_a) {
    var className = _a.className;
    return (React.createElement("svg", { className: className || "", width: '179', height: '48', viewBox: '0 0 179 48', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' },
        React.createElement("path", { d: 'M25.2665 41.5916H8.57144V20.0972H12.8535V37.7624H25.2665V41.5916Z', fill: 'black' }),
        React.createElement("path", { d: 'M28.9623 29.8488C28.0702 28.9553 27.4585 27.5513 27.4585 26.0196C27.4585 23.8753 28.3251 22.4968 29.7015 21.4757C31.4093 20.2248 33.7542 19.7143 36.507 19.7143C39.6421 19.7143 42.0125 20.1738 45.3516 22.0118L43.6438 25.3815C41.0695 24.0795 39.0559 23.5945 36.8129 23.5945C34.9777 23.5945 33.3719 23.7732 32.4798 24.488C32.021 24.8454 31.7916 25.3049 31.7916 25.9175C31.7916 26.4536 31.9955 26.8876 32.4288 27.1684C33.0405 27.5513 33.8562 27.73 34.5444 27.8576L38.801 28.5979C41.2224 29.0319 43.3125 29.7467 44.7143 31.1762C45.6065 32.1463 46.2182 33.4227 46.2182 35.1586C46.2182 37.5837 45.1477 39.2175 43.6693 40.162C41.7067 41.4639 38.5971 42 36.0227 42C32.1994 42 30.2368 41.4639 27.1526 39.9833L28.4781 36.435C31.2308 37.5837 33.3719 38.0432 35.9462 38.0432C38.6226 38.0432 39.9989 37.6858 41.1714 36.8689C41.6302 36.5115 41.8851 35.9755 41.8851 35.2607C41.8851 34.5714 41.5538 34.163 41.1714 33.7545C40.4577 33.2185 39.3872 32.9632 38.2402 32.7079L33.6268 31.9166C31.8935 31.6102 30.1348 31.0231 28.9623 29.8488Z', fill: 'black' }),
        React.createElement("path", { d: 'M52.3865 36.6136C54.0687 36.6136 55.0628 37.6603 55.0628 39.192C55.0628 40.6726 54.0687 41.7703 52.3865 41.7703C50.7807 41.7703 49.7357 40.6726 49.7357 39.192C49.7357 37.6603 50.7807 36.6136 52.3865 36.6136Z', fill: 'black' }),
        React.createElement("path", { d: 'M78.7927 20.0972V41.5916H74.9184L63.0917 27.0408V41.5916H58.8606V20.0972H62.7349L74.6381 34.6991V20.0972H78.7927Z', fill: 'black' }),
        React.createElement("path", { d: 'M83.814 20.0972H100.611V24.054H88.1216V28.4193H99.3111V32.325H88.1471V37.6858H101.121V41.5916H83.814V20.0972Z', fill: 'black' }),
        React.createElement("path", { d: 'M103.185 20.0972H122.812V23.9774H115.089V41.5916H110.908V23.9774H103.185V20.0972Z', fill: 'black' }),
        React.createElement("path", { d: 'M121.869 36.6136C123.551 36.6136 124.545 37.6603 124.545 39.192C124.545 40.6726 123.551 41.7703 121.869 41.7703C120.263 41.7703 119.218 40.6726 119.218 39.192C119.243 37.6603 120.263 36.6136 121.869 36.6136Z', fill: 'black' }),
        React.createElement("path", { d: 'M147.332 32.325C146.618 33.4227 145.573 34.3928 144.299 35.0054L148.351 41.5916H143.407L140.017 35.8989H134.078V41.5916H129.745V20.0972H139.966C142.565 20.0972 144.656 20.8886 146.185 22.4203C147.612 23.8498 148.555 25.7389 148.555 28.0364C148.555 29.5935 148.173 31.0231 147.332 32.325ZM134.078 31.9932H139.915C141.393 31.9932 142.565 31.5337 143.177 30.8189C143.891 30.0786 144.248 29.0319 144.248 28.0619C144.248 27.0663 143.916 25.9941 143.254 25.3049C142.616 24.488 141.495 24.054 139.889 24.054H134.103V31.9932H134.078Z', fill: 'black' }),
        React.createElement("path", { d: 'M167.442 39.4217C165.76 41.03 163.619 41.9745 160.892 41.9745C157.986 41.9745 155.743 40.9279 154.137 39.3196C152.43 37.5582 151.41 35.2097 151.41 32.1974V20.0972H155.743V32.3761C155.743 34.2651 156.355 35.5926 157.247 36.4605C158.216 37.5071 159.439 37.9922 160.892 37.9922C162.319 37.9922 163.619 37.3795 164.588 36.4095C165.454 35.5926 166.015 34.3417 166.015 32.3505V20.0972H170.348V32.1718C170.323 35.2862 169.252 37.6603 167.442 39.4217Z', fill: 'black' }),
        React.createElement("rect", { x: '40.2857', y: '1.71429', width: '32.5714', height: '5.14286', fill: '#00AD40' }),
        React.createElement("rect", { x: '105.429', y: '1.71429', width: '32.5714', height: '5.14286', fill: '#FD1F38' }),
        React.createElement("rect", { x: '72.8571', y: '1.71429', width: '32.5714', height: '5.14286', fill: '#F1F1F1' })));
};
export default StoreLogoIcon;
