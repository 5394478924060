import React from "react";
var FileTextIcon = function () {
    return (React.createElement("svg", { width: '28', height: '28', viewBox: '0 0 28 28', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' },
        React.createElement("rect", { width: '28', height: '28', fill: 'white' }),
        React.createElement("path", { d: 'M5 2C5 1.44772 5.44772 1 6 1H16.1502C16.4217 1 16.6816 1.11041 16.87 1.30586L17.5777 0.623469L16.87 1.30586L20.7802 5.3608L23.7198 8.40937C23.8996 8.59575 24 8.84458 24 9.1035V26C24 26.5523 23.5523 27 23 27H6C5.44772 27 5 26.5523 5 26V2Z', stroke: '#535557', strokeWidth: '2' }),
        React.createElement("path", { d: 'M16 1V8H23', stroke: '#535557', strokeWidth: '2', strokeLinejoin: 'round' }),
        React.createElement("rect", { x: '8', y: '21', width: '13', height: '2', rx: '1', fill: '#535557' }),
        React.createElement("rect", { x: '8', y: '17', width: '13', height: '2', rx: '1', fill: '#535557' }),
        React.createElement("rect", { x: '8', y: '13', width: '13', height: '2', rx: '1', fill: '#535557' })));
};
export default FileTextIcon;
