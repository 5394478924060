var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import "./RecentlyViewed.scss";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "Redux/hooks";
import { fetchApi } from "Utils/fetchApi";
import IntersectionObserverWrapper from "Components/IntersectionObserverWrapper/IntersectionObserverWrapper";
import MainCarousel from "Components/MainCarousel/MainCarousel";
var RecentlyViewed = function (_a) {
    var className = _a.className;
    var genderData = useAppSelector(function (state) { return state.app; }).genderData;
    var _b = __read(useState(true), 2), isLoading = _b[0], setIsLoading = _b[1];
    var _c = __read(useState(false), 2), isCarouselShown = _c[0], setIsCarouselShown = _c[1];
    var _d = __read(useState(), 2), recentlyViewedData = _d[0], setRecentlyViewedDataData = _d[1];
    useEffect(function () {
        isCarouselShown &&
            fetchApi({
                method: "GET",
                url: "/product/viewed?sex=".concat(genderData.genderNumber),
            }).then(function (_a) {
                var data = _a.data;
                setRecentlyViewedDataData(data.products);
                setIsLoading(false);
            });
    }, [isCarouselShown, genderData]);
    return (React.createElement("div", { className: "recentlyViewed__sub-wrapper" + (className ? " " + className : "") },
        React.createElement(IntersectionObserverWrapper, { querySelector: '.recentlyViewed__sub-wrapper', observerCallback: function () { return setIsCarouselShown(true); }, wasOnScreen: false },
            React.createElement("p", { className: 'recentlyViewed__title typography_h1' }, "\u0420\u0430\u043D\u0435\u0435 \u0441\u043C\u043E\u0442\u0440\u0435\u043B\u0438"),
            React.createElement(MainCarousel, { categoryOfGoodsData: recentlyViewedData, category: 'recentlyViewed', isLoading: isLoading }))));
};
export default RecentlyViewed;
