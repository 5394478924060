var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import "./ProductPrices.scss";
import React, { useEffect, useState } from "react";
import { reformatPrice } from "Utils/reformatPrice";
import { getPriceType } from "Utils/getPriceType";
import Label from "Components/Label/Label";
export var ProductPrices = function (_a) {
    var _b = _a.actual_price, actual_price = _b === void 0 ? 0 : _b, className = _a.className, _c = _a.dataIsRowUi, dataIsRowUi = _c === void 0 ? false : _c, _d = _a.price, price = _d === void 0 ? 0 : _d, _e = _a.sale_price, sale_price = _e === void 0 ? 0 : _e, style = _a.style, _f = _a.super_price, super_price = _f === void 0 ? false : _f, _g = _a.labelData, labelData = _g === void 0 ? null : _g;
    var _h = __read(useState("actual"), 2), priceType = _h[0], setPriceType = _h[1];
    useEffect(function () {
        setPriceType(getPriceType({ actual_price: actual_price, price: price, sale_price: sale_price }));
    }, [actual_price, price, sale_price]);
    return (React.createElement("div", { className: "productPrices" + (className ? " " + className : ""), "data-type": priceType, "data-super-price": super_price, "data-is_row_ui": dataIsRowUi, "data-withlabel": !!labelData, style: style },
        React.createElement("div", { className: "productPrices__main" + (super_price ? " productPrices__main_super" : "") },
            React.createElement("div", { className: 'productPrices__main__price productPrices__price' }, reformatPrice(actual_price)),
            super_price && React.createElement("div", { className: 'productPrices__main__label productPrices__price_sale' }, "sale \u043D\u0435\u0434\u0435\u043B\u0438")),
        priceType === "sale" && (React.createElement("div", { className: 'productPrices__sale' },
            React.createElement("div", { className: 'productPrices__sale__price productPrices__price_sale productPrices__price_crossed' }, reformatPrice(price)))),
        labelData && React.createElement(Label, { className: 'productPrices__label', labelData: labelData, size: 'small' })));
};
