import "./SwitchPanel.scss";
import React from "react";
var SwitchPanel = function (_a) {
    var className = _a.className, controlsData = _a.controlsData, clickHandler = _a.clickHandler;
    return (React.createElement("div", { className: "switchPanel" + (className ? " ".concat(className) : "") }, controlsData.map(function (_a) {
        var isActive = _a.isActive, txt = _a.txt, type = _a.type;
        return (React.createElement("button", { className: "switchPanel__item typography_tec_button" + (isActive ? " switchPanel__item_active" : ""), key: txt, onClick: function () { return clickHandler(type); } }, txt));
    })));
};
export default SwitchPanel;
