export var getPriceType = function (_a) {
    var actual_price = _a.actual_price, price = _a.price, sale_price = _a.sale_price;
    if (actual_price === price && sale_price === 0) {
        return "actual";
    }
    else if (sale_price > 0 || sale_price === 0) {
        return "sale";
    }
    return "undef";
};
