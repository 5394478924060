import React from "react";
var ExclamationMarkIcon = function (_a) {
    var className = _a.className;
    return (React.createElement("svg", { className: className || "", width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' },
        React.createElement("g", { clipPath: 'url(#clip0_12574_66475)' },
            React.createElement("circle", { cx: '12', cy: '12', r: '12', fill: '#EB5757' }),
            React.createElement("path", { d: 'M12 7L12 13', stroke: 'white', strokeWidth: '2', strokeLinecap: 'round' }),
            React.createElement("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M12 18C12.5523 18 13 17.5523 13 17C13 16.4477 12.5523 16 12 16C11.4477 16 11 16.4477 11 17C11 17.5523 11.4477 18 12 18Z', fill: 'white', stroke: 'white' })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: 'clip0_12574_66475' },
                React.createElement("rect", { width: '24', height: '24', fill: 'white' })))));
};
export default ExclamationMarkIcon;
