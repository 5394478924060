import "./Label.scss";
import React from "react";
var Label = function (_a) {
    var className = _a.className, labelData = _a.labelData, _b = _a.isAdaptive, isAdaptive = _b === void 0 ? true : _b, _c = _a.isStack, isStack = _c === void 0 ? false : _c, size = _a.size;
    return labelData ? (React.createElement("div", { className: "label" +
            (className ? " ".concat(className) : "") +
            (size === "small" ? " label_small" : "") +
            (isAdaptive && !size ? " label_adaptive" : "") +
            (isStack ? " label_stack" : ""), style: { backgroundColor: labelData.color } },
        React.createElement("span", null, labelData.text))) : null;
};
export default Label;
