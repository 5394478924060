import "./GenderSelection.scss";
import React from "react";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { changeGender } from "Redux/slices/appSlice";
import { genderList } from "Static/texts";
var GenderSelection = function (_a) {
    var className = _a.className;
    var dispatch = useAppDispatch();
    var genderData = useAppSelector(function (state) { return state.app; }).genderData;
    return (React.createElement("div", { className: "gender-selection" + (className ? " " + className : "") }, genderList.map(function (gender) { return (React.createElement("p", { className: "typography_tec_13_semiBold gender-selection__item" +
            (genderData.genderNumber === gender.genderNumber ? " gender-selection__item_active" : ""), key: gender.genderNumber, onClick: function () { return dispatch(changeGender(gender)); } }, gender.gender)); })));
};
export default GenderSelection;
